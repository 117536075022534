import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getPageTitle } from '@shared/helpers/global-helper';

import StaticHex from '@shared/components/static-hex';
import StaticCoin from '@shared/components/static-coin';
import Line from '@shared/components/line';
import ArrowRight from '@shared/icons/arrow-right';

const About = () => {
    const navigate = useNavigate();

    return <div className="about wrapper">
        {getPageTitle('ABOUT')}
        <h2>About</h2>
        <h4>Introduction</h4>
        <p>The I Ching, also known as the <i>Book of Changes</i>, is an ancient Chinese divination text rooted in Taoist and Confucian philosophy. Divination is a means towards gaining insight into a question or situation using ritualized or occult practice.  Consulting the I Ching is a communion between the questioner and the text, a conversation with a living body of knowledge. It is ultimately a vehicle for the seeker to find the answer from within, a coming to meet with the conscious mind and the subconscious divine mind.</p>

        <p>The text of the I Ching is split into 64 different units, called hexagrams. The I Ching itself is meant to represent the circular movement of existence with each hexagram representing a particular stage of the cycle.  Each hexagram consists of six horizontal lines stacked vertically. Each line is either broken or unbroken; broken lines represent the yin, or feminine aspect, while broken lines represent yang, or the male aspect.</p>

        <div className="example">
            <div className="example-row">
                <StaticHex
                    hexKey="111111"
                    hexNum={1}
                    title="The Creative"
                    displayTitles={true}
                    onHexClick={navigate.bind(null, '/hexagrams/1')}
                />
                <StaticHex
                    hexKey="010101"
                    hexNum={64}
                    title="Before Completion"
                    displayTitles={true}
                    onHexClick={navigate.bind(null, '/hexagrams/64')}
                />
            </div>
            <div className="caption">The first and last hexagrams of the I Ching.</div>
        </div>

        <h4>Trigrams</h4>
        <p>Each hexagram consists of two stacked trigrams, which individually represent a particular element. The eight elements—heaven, earth, thunder, water, mountain, wind, fire, and lake—embody the fundamental principles of reality in Taoist cosmology. Hexagrams with two stacked matching trigrams are pure expressions of that particular element.
        </p>

        <div className="example trigrams">
            <div className="example-row">
                <StaticHex
                    hexKey="111111"
                    hexNum={1}
                    title="The Creative"
                    displayTitles={true}
                    caption="Heaven"
                    onHexClick={navigate.bind(null, '/hexagrams/1')}
                />
                <StaticHex
                    hexKey="000000"
                    hexNum={2}
                    title="The Receptive"
                    displayTitles={true}
                    caption="Earth"
                    onHexClick={navigate.bind(null, '/hexagrams/2')}
                />
                <StaticHex
                    hexKey="100100"
                    hexNum={51}
                    title="The Arousing"
                    displayTitles={true}
                    caption="Thunder"
                    onHexClick={navigate.bind(null, '/hexagrams/51')}
                />
                <StaticHex
                    hexKey="010010"
                    hexNum={29}
                    title="The Abysmal"
                    displayTitles={true}
                    caption="Water"
                    onHexClick={navigate.bind(null, '/hexagrams/29')}
                />
            </div>
            <div className="example-row">
                <StaticHex
                    hexKey="001001"
                    hexNum={52}
                    title="Keeping Still"
                    displayTitles={true}
                    caption="Mountain"
                    onHexClick={navigate.bind(null, '/hexagrams/52')}
                />
                <StaticHex
                    hexKey="011011"
                    hexNum={57}
                    title="The Gentle"
                    displayTitles={true}
                    caption="Wind"
                    onHexClick={navigate.bind(null, '/hexagrams/57')}
                />
                <StaticHex
                    hexKey="101101"
                    hexNum={30}
                    title="The Clinging"
                    displayTitles={true}
                    caption="Fire"
                    onHexClick={navigate.bind(null, '/hexagrams/30')}
                />
                <StaticHex
                    hexKey="110110"
                    hexNum={58}
                    title="The Joyous"
                    displayTitles={true}
                    caption="Lake"
                    onHexClick={navigate.bind(null, '/hexagrams/58')}
                />
            </div>
            <div className="caption">Hexagrams that embody the eight fundamental elements.</div>
        </div>

        <h4>Changing Lines</h4>
        <p>Hexagrams can also having changing lines, signified by a dot. A broken line changes into an unbroken line and vice versa; a changing line is considered old (e.g. old yin) while an unchanging line is considered young (e.g. young yang). The fundamental philosphy of the I Ching is rooted in the belief that life is constantly moving from one state to the next (hence <i>Book of Changes</i>). Periods of statis only last for so long—prosperity deteriorates into stagnation, increase into decrease, peace into conflict, and so forth—thusly, a prognostication from the oracle also can change from one outcome to the next.</p>

        <div className="example changing">
            <div className="example-row">
                <StaticHex
                    hexKey={'110110'}
                    hexNum={58}
                    displayTitles={true}
                    changingLines={[1, 4, 5, 6]}
                    title="The Joyous Lake"
                    onHexClick={navigate.bind(null, '/hexagrams/58')}
                />
                <div className="changes-into">
                    <div className="arrow-right">
                        <ArrowRight />
                    </div>
                    changes into
                </div>
                <StaticHex
                    hexKey={'010001'}
                    hexNum={4}
                    displayTitles={true}
                    title="Youthful Folly"
                    onHexClick={navigate.bind(null, '/hexagrams/4')}
                />
            </div>
            <div className="caption">Hexagram 58 with changing lines 1, 4, 5, and 6 changes into hexagram 4.</div>
        </div>

        <h4>Divination Method</h4>
        <p>There are various methods towards procuring a reading from the I Ching. The ancient original method involves manipulating 50 yarrow stalks, the details of which can be found online elsewhere. The Ask I Ching app utilizes the second quicker and more popular method which involves tossing three coins.</p>
        <p>When the seeker has settled on a question or subject on which to ask the oracle, she tosses three coins six different times in order to calculate each line.  The various combinations of how each coin lands dictates whether it is a yin or yang line and if it is changing.</p>
        <div className="example divination">
            <div className="example-row">
                <ul className="coins-row">
                    <li>
                        <div className="line-label">Young Yin</div>
                        <ul className="hexagram">
                            <Line
                                isSolid={0}
                            />
                        </ul>
                        <div className="coin-wrapper">
                            <StaticCoin
                                coinType="tails"
                            />
                            <StaticCoin
                                coinType="tails"
                            />
                            <StaticCoin
                                coinType="heads"
                            />
                        </div>
                    </li>
                    <li>
                        <div className="line-label">Young Yang</div>
                        <ul className="hexagram">
                            <Line
                                isSolid={1}
                            />
                        </ul>
                        <div className="coin-wrapper">
                            <StaticCoin
                                coinType="heads"
                            />
                            <StaticCoin
                                coinType="heads"
                            />
                            <StaticCoin
                                coinType="tails"
                            />
                        </div>
                    </li>
                    <li>
                        <div className="line-label">Old Yin</div>
                        <ul className="hexagram">
                            <Line
                                isSolid={0}
                                isChanging={true}
                            />
                        </ul>
                        <div className="coin-wrapper">
                            <StaticCoin
                                coinType="heads"
                            />
                            <StaticCoin
                                coinType="heads"
                            />
                            <StaticCoin
                                coinType="heads"
                            />
                        </div>
                    </li>
                    <li>
                        <div className="line-label">Old Yang</div>
                        <ul className="hexagram">
                            <Line
                                isSolid={1}
                                isChanging={true}
                            />
                        </ul>
                        <div className="coin-wrapper">
                            <StaticCoin
                                coinType="tails"
                            />
                            <StaticCoin
                                coinType="tails"
                            />
                            <StaticCoin
                                coinType="tails"
                            />
                        </div>
                    </li>
                </ul>
                <div className="caption">The app gives heads the value of 2 and tails the value of 3. (These values can be switched as long as it is consistent). Six = old yin. Seven = young yang. Eight = young yin. Nine = old yang.</div>
            </div>
        </div>

        <h4>The Translation</h4>
        <p>The app uses the Richard Wilhelm and Cary F. Baynes translation of the I Ching. This is the most widely known and probably the definitive version. However, it is definitely not the most digestible version for modern readers. As with dealing with any translated text, context must always be taken into account.</p>

        <p>Firstly,  we are culturally quite a ways off from the values, attitudes, and dispositions of those who lived in ancient China, so even if we had a word for word translation of the text, it would be still be too arcane for most people. Secondly, this version is a translation of a translation (Wilhelm's German translation was published in 1924, and Baynes' German to English translation published in 1950), and thus is filtered through two people with two unique cultural perspectives rooted in a specific era in time.</p>

        <p>For example, there are many references that the "rightful place" of a women is in subservience to a man. One would assume that most modern Western women would feel alienated by this and therefore dismiss the I Ching entirely. However, one must consider that the text originally comes from ancient China, an extremely patriachal culture rooted in Confucian ethics and philosphy, and is translated by a man from post World War Germany, translated by another man from post World War II England. Indeed, there are some noted instances where Wilhelm added a female pronoun colored with a notably conservative male perspective where the original text did not.</p>

        <p>Regardless, one must be able to look past these particular cultural biases and be able to read and understand the text on a more symbolic, metaphorical level in order to utilize the insight and wisdom the I Ching has to offer. Ultimately, the translation is a product of its time, words filtered through the nuance of a particular individual in a certain historical and cultural context, and not the actual I Ching itself.</p>

        <p>However, it must be strongly emphasized that the translated text in each hexagram readings are the poetic verses in "The Judgment," "The Image,"" and "The Lines"—everything else is Wilhelm's interpretation of the overall hexagram's meaning. It is therefore encouraged that one additionally consult other translations of the text so that they are better able to both understand it more easily via a style and temperament that may be more agreeable to modern sensibilities and also gain a more holistic and balanced personal interpretation of the I Ching in general.</p>

        {/*<h4>The Reading</h4>

        <p>As previously stated, the actual hexagram text consists of different poetic verses called "The Judgment," "The Image," and "The Lines," with Richard Wilehelm's interpretation of each of these sections filling out the rest.</p>

        <p>Although "The Image" is presented after "The Judgment" within the text of each hexagram, it is important to talk about it first in order to understand the I Ching further. Each hexagram, with their six stacked lines, represents an image, which may or may not represent an actual physical object in nature akin to a hieroglyph. This image, however, more importantly represents a state of change.</p>

        <p>According to Taoist philosophy all events in the physical world are created as an effect of an "image" of the unseen world. </p>*/}
    </div>;
};

export default About;
