import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';

import actions from '@actions';
import { Share, Facebook, Twitter } from '@shared/icons';
import { getReadingUrl } from '@shared/helpers/hexagram-helper';

const ShareButton = ({
    savedReadings,
    appData,
    question,
    hexNum,
    changingLines,
    toggleSocialBlockedModal,
    isIcon
}) => {
    const [isShareOpened, setIsShareOpened] = useState(false);

    useEffect(() => {
        window.twttr && window.twttr.ready((twttr) => (
            twttr.widgets.load()
        ));
    }, []);

    const handleShareClick = () => {
        setIsShareOpened(true);
    };

    const location = useLocation();

    const getShareLink = () => {
        const split = location.pathname.split('/');
        let query;

        if (split[1] === 'saved-readings' && split[2]) {
            const id = split[2];
            const savedReading = savedReadings.find(o => o.id === id);

            query = getReadingUrl(
                savedReading.question,
                savedReading.hexData.number,
                savedReading.changingLines
            );
        } else {
            query = getReadingUrl(question, hexNum, changingLines);
        }

        return appData.origin + query;
    };

    const getTwtterShareMsg = () => {
        const { origin } = appData;
        let msg = `Check out my reading on ${origin}! `;
        msg = escape(msg + getShareLink());
        return msg;
    };

    const fbShare = () => {
        window.FB.ui({
            method: 'share',
            href: getShareLink(),
        }, (response) => {});
    };

    const handleSocialButtonClick = (e) => {
        try {
            if (e.currentTarget.id === 'facebook-btn') {
                fbShare();
            } else {
                // This is test just to detect if the twitter code
                // has been loaded
                window.twttr.widgets.load();
            }

            setTimeout(() => (
                setIsShareOpened(false)
            ), 1000);

        } catch (err) {
            e.preventDefault();
            toggleSocialBlockedModal(true);
        }
    };

    return (
        <div className="share-buttons">
            <button
                className={classNames('share-btn', { 'icon-btn': isIcon && !isShareOpened }, { 'share-open': isShareOpened })}
                onClick={handleShareClick}>
                { !isShareOpened ? (isIcon ? <Share/> : 'Share') : ''}
                <CSSTransition
                    timeout={400}
                    classNames="share-btn"
                    in={isShareOpened}
                >
                    <div className="social-buttons">
                        {/* eslint-disable jsx-a11y/anchor-is-valid */}
                        <a
                            id="facebook-btn"
                            className="facebook-btn icon-btn"
                            onClick={handleSocialButtonClick}>
                            <Facebook size={'28px'}/>
                        </a>
                        <a
                            id="twitter-btn"
                            className="twitter-btn icon-btn"
                            href={'https://twitter.com/intent/tweet?text=' + getTwtterShareMsg()}
                            onClick={handleSocialButtonClick}>
                            <Twitter height="28px" width="27px" />
                        </a>
                        {/* eslint-enable */}
                    </div>
                </CSSTransition>
            </button>

        </div>
    );
};

ShareButton.propTypes = {
    isIcon: PropTypes.bool
};

ShareButton.defaultProps = {
    isIcon: false
};

const mapStateToProps = (state) => ({
    question: escape(state.question),
    hexNum: state.oracleHexNum,
    changingLines: state.oracleChangingLines,
    savedReadings: state.savedReadings,
    appData: state.appData
});

const ShareButtonContainer = connect(
    mapStateToProps,
    {
        toggleSocialBlockedModal: actions.toggleSocialBlockedModal
    }
)(ShareButton);

export default ShareButtonContainer;
