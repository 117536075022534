/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import PropTypes from 'prop-types';

const Alert = ({ size, fill }) => (
    <svg version="1.1" id="Ebene_1" x="0px" y="0px" viewBox="0 0 1280 1024" enableBackground="new 0 0 1280 1024" width={size} height={size}>
        <g>
            <rect x="624" y="704" width="32" height="32"/>
            <path fill={fill} d="M640,80c-236.8,0-432,195.2-432,432s195.2,432,432,432s432-195.2,432-432S876.8,80,640,80z M640,912
                c-220.8,0-400-179.2-400-400s179.2-400,400-400s400,179.2,400,400S860.8,912,640,912z"/>
            <rect x="624" y="256" width="32" height="384"/>
        </g>
    </svg>
);

Alert.propTypes = {
    size: PropTypes.string,
    fill: PropTypes.string
};

Alert.defaultProps = {
    size: '32px',
    fill: '#FDF1F1'
};

export default Alert;
