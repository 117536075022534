import { types } from '@actions';

export const staticHexKey = (state = null, action) => {
    switch (action.type) {
    case types.STATIC_HEXAGRAM_COMPLETE:
        return action.hexKey;
    default:
        return state;
    }
};

export const staticHexagramText = (state = null, action) => {
    switch (action.type) {
    case types.FETCH_STATIC_HEXAGRAM_TEXT_SUCCESS:
        return action.response.body;
    case types.FETCH_STATIC_HEXAGRAM_TEXT_FAILURE:
    default:
        return state;
    }
};

