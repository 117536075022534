import { types } from '@actions';

export const appData = (state = {}, action) => {
    switch (action.type) {
    default:
        return state;
    }
};

export const bannerMsg = (state = '', action) => {
    switch (action.type) {
    case types.TOGGLE_BANNER:
        return action.bannerMsg || '';
    default:
        return state;
    }
};

export const isBannerShown = (state = false, action) => {
    switch (action.type) {
    case types.TOGGLE_BANNER:
        return action.shouldShow;
    default:
        return state;
    }
};

export const menu = (state = { isOpen: false }, action) => {
    switch (action.type) {
    case types.TOGGLE_MENU:
        const { isOpen } = action.payload;
        return { ...state, isOpen };
    default:
        return state;
    }
};

export const isSocialBlockedModalShown = (state = false, action) => {
    switch (action.type) {
    case types.TOGGLE_SOCIAL_BLOCKED_MODAL:
        return action.shouldShow;
    default:
        return state;
    }
};
