export const types = {
    STATIC_HEXAGRAM_COMPLETE: 'STATIC_HEXAGRAM_COMPLETE',
    FETCH_STATIC_HEXAGRAM_TEXT_SUCCESS: 'FETCH_STATIC_HEXAGRAM_TEXT_SUCCESS',
    FETCH_STATIC_HEXAGRAM_TEXT_FAILURE: 'FETCH_STATIC_HEXAGRAM_TEXT_FAILURE'
};

export const onStaticHexagramComplete = (hexKey) => ({
    type: types.STATIC_HEXAGRAM_COMPLETE,
    hexKey
});

export const fetchStaticHexagramTextSuccess = (response) => ({
    type: types.FETCH_STATIC_HEXAGRAM_TEXT_SUCCESS,
    response
});

export const fetchStaticHexagramTextFailure = (error) => ({
    type: types.FETCH_STATIC_HEXAGRAM_TEXT_FAILURE,
    error
});
