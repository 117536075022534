/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import PropTypes from 'prop-types';

const Close = ({ size, fill }) => (
    <svg id="Ebene_1" version="1.1" height={size} id="svg2" width={size} viewBox="0 0 32 32" x="0px" y="0px" xmlSpace="preserve">
        <polygon fill={fill} points="29.7,3.7 28.3,2.3 16,14.6 3.7,2.3 2.3,3.7 14.6,16 2.3,28.3 3.7,29.7 16,17.4 28.3,29.7 29.7,28.3 17.4,16 "/>
    </svg>
);

Close.propTypes = {
    size: PropTypes.string,
    fill: PropTypes.string
};

Close.defaultProps = {
    size: '32px',
    fill: '#FDF1F1'
};

export default Close;
