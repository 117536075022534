import React from 'react';
import { Link } from 'react-router-dom';
import { getPageTitle } from '@shared/helpers/global-helper';

import heads from '@shared/images/coin-heads.svg';

const Home = () => (
    <div className="wrapper home">
        {getPageTitle('HOME')}
        <div className="content">
            <div className="text-content">
                <h1>
                    Develop your intuition<br/>
                    with Ask I Ching
                </h1>
                <h2>Free I Ching Online Readings</h2>
                <p>
                    Find clarity and insight into your deepest questions using this
                    ancient divination text. Clear your mind, get ready to toss the coins,
                    and prepare to discover your answer from one of the 64 hexagrams.
                </p>
                <Link to="/oracle">
                    <button className="ask-btn">Ask the oracle</button>
                </Link>
            </div>
            <div className="image-content">
                <span className="coin">
                    <div className="flipper">
                        <div className="front">
                            <img src={heads} alt="iching coin" />
                        </div>
                    </div>
                </span>
            </div>
        </div>
    </div>
);

export default Home;
