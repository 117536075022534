import { takeLatest, put, take } from 'redux-saga/effects';
import actions, { types } from '@actions';

export function* sharedReadingSaga({ hexNum, changingLines }) {
    try {
        yield put(actions.fetchHexagramText(hexNum, 'shared', changingLines));
        yield take(types.FETCH_SHARED_HEXAGRAM_TEXT_SUCCESS);
    } catch (error) {
        // TODO: Handle API error/add logging
        console.error(error); // eslint-disable-line no-console
    }
}

export function* watchSharedReading() {
    yield takeLatest(types.SET_SHARED_READING_DATA, sharedReadingSaga);
}
