import throttle from 'lodash/throttle';
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';

import { loadState, saveState } from './local-storage';
import createRootReducer from './reducers';
import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();

export const configureStore = (preloadedState) => {
    const persistedState = loadState();

    const store = createStore(
        createRootReducer(),
        {
            ...preloadedState,
            ...persistedState
        },
        composeWithDevTools(applyMiddleware(
            sagaMiddleware,
        ))
    );

    store.subscribe(throttle(() => {
        const { savedReadings } = store.getState();
        const stateToSave = { savedReadings };

        saveState(stateToSave);
    }, 1000, { leading: false, trailing: true }));

    sagaMiddleware.run(rootSaga);

    return {
        store
    };
};

export const configureServerStore = (initialState) => {
    const store = createStore(
        createRootReducer(),
        initialState,
        applyMiddleware(sagaMiddleware)
    );

    const appSaga = sagaMiddleware.run(rootSaga);

    return {
        store,
        appSaga
    };
};
