import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import throttle from 'lodash/throttle';
import classNames from 'classnames';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import ListItem from './components/list-item';
import { Edit, Trash } from '@shared/icons';
import actions from '@actions';
import { isNode, getPageTitle } from '@shared/helpers/global-helper';

const SavedReadings = ({ savedReadings, deleteReading }) => {
    const [isClient, setIsClient] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [readingsToDelete, setReadingsToDelete] = useState({});
    const [initiateDelete, setInitiateDelete] = useState(false);

    useEffect(() => {
        // Need to do this b/c suppressHydrationWarning in React 18 cannot be
        // used for dom tree differences
        // https://github.com/facebook/react/issues/25627#issuecomment-1444073227
        setIsClient(true);
        moment.locale(
            isNode() ? 'en-US' : window.navigator.language
        );
    }, []);

    const onEditBtnClick = () => {
        setIsEditing(!isEditing);
        setReadingsToDelete({});
    };

    const onCheckBtnClick = (id) => {
        const newReadingsToDelete = { ...readingsToDelete };

        if (newReadingsToDelete[id]) {
            delete newReadingsToDelete[id];
        } else {
            newReadingsToDelete[id] = id;
        }

        setReadingsToDelete(newReadingsToDelete);
    };

    const onTrashBtnClick = () => {
        if (!isEmpty(readingsToDelete)) {
            setInitiateDelete(true);
        }
    };

    const onTransitionEnd = (e) => {
        e.persist();
        throttledOnRemoveTransitionEnd(e);
    };

    const onRemoveTransitionEnd = (e) => {
        // Have to check if the animation is on the height on the li
        if (e.target.nodeName === 'LI') {
            setInitiateDelete(false);
            setIsEditing(false);

            deleteReading(readingsToDelete);
            setReadingsToDelete({});
        }
    };

    const throttledOnRemoveTransitionEnd = useMemo(() => throttle(
        onRemoveTransitionEnd, 1000, { leading: false, trailing: true }
    ), [deleteReading, readingsToDelete]); // eslint-disable-line

    const navigate = useNavigate();
    const onSavedReadingClick = (id) => {
        if (!isEditing) {
            navigate(`/saved-readings/${id}`);
        }
    };

    return (
        <div className="wrapper saved-readings">
            <h2>Saved Readings</h2>
            {getPageTitle('SAVED_READINGS')}
            {
                isClient && savedReadings.length ?
                    <div>
                        <div className="button-container">
                            {
                                isEditing ?
                                    <button className={classNames('trash-btn', { 'disabled': isEmpty(readingsToDelete) })}
                                        onClick={onTrashBtnClick}>
                                        <Trash />
                                    </button>
                                    : ''
                            }
                            <button className="edit-btn" onClick={onEditBtnClick}>
                                <Edit />
                            </button>
                        </div>
                        <ul className={classNames('saved-readings-list', { 'is-editing': isEditing })}>
                            {
                                savedReadings.map(reading => <ListItem
                                    key={`saved-reading-${reading.id}`}
                                    reading={reading}
                                    shouldRemove={initiateDelete && readingsToDelete[reading.id]}
                                    onClick={onSavedReadingClick}
                                    onCheckClick={onCheckBtnClick}
                                    isSelected={!!readingsToDelete[reading.id]}
                                    onTransitionEnd={onTransitionEnd}

                                />
                                )
                            }
                        </ul>
                    </div>
                    : <p className="no-saved-msg">You have no saved readings yet.</p>
            }
        </div>
    );
};

const mapStateToProps = (state) => ({
    savedReadings: state.savedReadings
});

const SavedReadingsContainer = connect(
    mapStateToProps,
    {
        deleteReading: actions.deleteReading,
    }
)(SavedReadings);

export default SavedReadingsContainer;
