import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import StaticHex from '@shared/components/static-hex';
import HexagramText from '@shared/components/hexagram-text';
import ShareButton from '@shared/components/share-buttons';
import { getPageTitle } from '@shared/helpers/global-helper';
import actions from '@actions';
import { getSavedReadingHexagram } from './../selectors';
import isEmpty from 'lodash/isEmpty';

const SavedReading = (props) => {
    const {
        loadSavedReading,
        savedReadings,
        hexagram,
        reading,
        question,
    } = props;

    const navigate = useNavigate();
    const redirect = useCallback(() => navigate('/saved-readings'), [navigate]);

    const location = useLocation();

    useEffect(() => {
        const path = location.pathname;
        const id = path && path.split('/')[2];

        if (id) {
            const savedReading = savedReadings.find(o => o.id === id);
            if (savedReading) {
                loadSavedReading(savedReading);
            } else {
                redirect();
            }
        }
    }, []); // eslint-disable-line

    if (isEmpty(hexagram)) {
        return <div className="wrapper saved-reading" suppressHydrationWarning></div>;
    }

    return <div className="wrapper saved-reading">
        {getPageTitle('SAVED_READING', question)}
        <article className="oracle reading-open">
            <h2 className="question-title show-question">{question}</h2>
            <div className="hexagram-wrapper show">
                <StaticHex
                    hexKey={hexagram.hexKey}
                    title={hexagram.hexTitle}
                    hexNum={hexagram.hexNum}
                    changingLines={hexagram.changingLines}
                    displayTitles={false}
                />
                <div className="hex-description">
                    <h2 id="hexNum" className="hex-num h1">{hexagram.hexNum}</h2>
                    <p className="hex-name h1">{hexagram.hexTitle}</p>
                    <ShareButton isShown isIcon/>
                </div>
            </div>
        </article>
        <HexagramText
            onCloseClick={redirect}
            hexagram={hexagram}
            reading={reading}
            animated={false}
            hasAlreadySaved={() => true}
        />
    </div>;
};

const mapStateToProps = (state) => ({
    question: state.savedReading.question,
    hexagram: getSavedReadingHexagram(state),
    reading: state.savedReading.hexagramText,
    savedReadings: state.savedReadings
});

const mapDispatchToProps = {
    loadSavedReading: actions.loadSavedReading
};

export default connect(mapStateToProps, mapDispatchToProps)(SavedReading);
