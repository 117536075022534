import HomeContainer from './home';
import OracleContainer from './oracle';
import HexagramsContainer from './hexagrams';
import HexagramContainer from './hexagrams/hexagram';
import SavedReadingsContainer from './saved-readings';
import SavedReadingContainer from './saved-readings/saved-reading';
import MissingRouteContainer from './missing-route';
import ReadingContainer from './reading';
import AboutContainer from './about';
import PrivacyPolicyContainer from './privacy-policy';

const sharedRoutes = [
    {
        path: '/',
        element: <HomeContainer />
    },
    {
        path: '/oracle',
        element: <OracleContainer />
    },
    {
        path: '/hexagrams',
        element: <HexagramsContainer />
    },
    {
        path: '/hexagrams/:hexNum',
        element: <HexagramContainer />,
        loadData: HexagramContainer.loadData
    },
    {
        path: '/about',
        element: <AboutContainer />
    },
    {
        path: '/privacy-policy',
        element: <PrivacyPolicyContainer />
    },
    {
        path: '/saved-readings',
        element: <SavedReadingsContainer />
    },
    {
        element: <MissingRouteContainer />
    }
];

export const ClientRoutes = [
    {
        path: '/reading',
        element: <ReadingContainer />
    },
    {
        path: '/saved-readings/:id',
        element: <SavedReadingContainer />
    },
    ...sharedRoutes,
];

export const ServerRoutes = [
    {
        path: '/reading',
        element: <ReadingContainer />
    },
    {
        path: '/saved-readings/:id',
        element: <SavedReadingsContainer />
    },
    ...sharedRoutes
];
