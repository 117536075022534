/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import PropTypes from 'prop-types';

const ArrowRight = ({ size, fill }) => (
    <svg id="Ebene_1" width={size} height={size} version="1.1" viewBox="0 0 32 32" x="0px" y="0px" xmlSpace="preserve">
        <polygon fill={fill} points="18.7,3.3 17.3,4.7 27.6,15 2,15 2,17 27.6,17 17.3,27.3 18.7,28.7 31.4,16 "/>
    </svg>
);

ArrowRight.propTypes = {
    size: PropTypes.string,
    fill: PropTypes.string
};

ArrowRight.defaultProps = {
    size: '32px',
    fill: '#FDF1F1'
};

export default ArrowRight;
