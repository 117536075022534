import { put, takeLatest } from 'redux-saga/effects';
import actions, { types } from '@actions';

export function* saveReadingSaga() {
    yield put(actions.toggleBanner(true, 'Your reading has been saved!'));
}

export function* watchSaveReading() {
    yield takeLatest(types.SAVE_READING, saveReadingSaga);
}
