import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { APP, HEX_MAP } from '@constants';
import HexagramText from '@shared/components/hexagram-text';
import actions from '@actions';
import { getPageTitle } from '@shared/helpers/global-helper';
import { getStaticHexagram } from './../selectors';

const getHexNumFromUrl = url => parseInt(url.split('/')[2], 10);

const getHexKeyFromUrl = (url) => {
    const hexNum = getHexNumFromUrl(url);
    return Object.keys(HEX_MAP).find(key => HEX_MAP[key].number === hexNum);
};

const Hexagram = (props) => {
    const {
        onStaticHexagramComplete,
        hexagramText,
        hexagram,
    } = props;

    const location = useLocation();

    useEffect(() => {
        const path = location.pathname;
        const hexNum = getHexNumFromUrl(path);

        // Only call request on client if there is no data
        // or it does not match
        if (!hexagramText || hexNum !== hexagram.hexNum) {
            onStaticHexagramComplete(getHexKeyFromUrl(path));
        }
    }, []); // eslint-disable-line

    // If there is no data an error will occur client side
    const title = hexagram.hexKey ? HEX_MAP[hexagram.hexKey].title : APP.TITLES.HEXAGRAM;

    const navigate = useNavigate();

    return (
        <div className="wrapper static-reading">
            {getPageTitle(title)}
            <HexagramText
                onCloseClick={navigate.bind(null, -1)}
                hexagram={hexagram}
                reading={hexagramText}
                animated={false}
                hasAlreadySaved={() => true}
                showShare={false}
            />
        </div>
    );
};

const mapStateToProps = (state) => ({
    hexagramText: state.staticHexagramText,
    hexagram: getStaticHexagram(state),
});

const mapDispatchToProps = {
    onStaticHexagramComplete: actions.onStaticHexagramComplete,
};

// Add action as loadData property for server rendering requirements
Hexagram.loadData = url => actions.onStaticHexagramComplete(getHexKeyFromUrl(url));

export default connect(mapStateToProps, mapDispatchToProps)(Hexagram);
