import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, useLocation, useRoutes } from 'react-router-dom';
import { StaticRouter } from "react-router-dom/server";
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import AppContainer from './app';
import { ClientRoutes, ServerRoutes } from './routes';

const RootApp = ({ routes }) => {
    const location = useLocation();
    return (
        <AppContainer>
            <TransitionGroup>
                <CSSTransition key={location.key} timeout={500} classNames="page-transition">
                    {useRoutes(routes)}
                </CSSTransition>
            </TransitionGroup>
        </AppContainer>
    );
};

export const ClientRoot = ({ store }) => (
    <Provider store={store}>
        <BrowserRouter>
            <RootApp routes={ClientRoutes} />
        </BrowserRouter>
    </Provider>
);

export const ServerRoot = ({ store, location }) => (
    <Provider store={store}>
        <StaticRouter location={location}>
            <RootApp routes={ServerRoutes} />
        </StaticRouter>
    </Provider>
);
