const QUESTION = {
    PLACEHOLDER_TEXT: [
        'Consult the I Ching',
        'Mercury is in retrograde. Help!',
        'Who\'s better, Nickelback or Creed?',
        'Is gluten free pizza the right move?',
        'Is night cheese my destiny?'
    ],
    PLACEHOLDER_INTERVAL: 30000,
    ERROR_TEXT: [
        'Ask and ye shall find.',
        'There is no answer without a question.',
        'The oracle says ask question.',
        'If a tree falls in a forest and no one is around to hear it, does it make a sound?'
    ],
    QUESTION_MAX_LENGTH: 50
};

export default QUESTION;
