import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-responsive-modal';
import Alert from '@shared/icons/alert';
import { toggleSocialBlockedModal } from './../actions';

const SocialBlockedModal = ({ isSocialBlockedModalShown, toggleSocialBlockedModal }) => {
    return <div>
        <Modal
            open={isSocialBlockedModalShown}
            onClose={toggleSocialBlockedModal.bind(null, false)}
            center classNames={{ modal: 'social-blocked-modal', closeButton: 'close-btn' }}>
            <Alert />
            <h2>You are using an ad or social media blocker. Please white-list this website and reload the page in order to utilize this feature.</h2>
        </Modal>
    </div>;
};

const mapStateToProps = (state) => ({
    isSocialBlockedModalShown: state.isSocialBlockedModalShown,
});

const mapDispatchToProps = {
    toggleSocialBlockedModal
};

export default connect(mapStateToProps, mapDispatchToProps)(SocialBlockedModal);
