import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import StaticHex from '@shared/components/static-hex';
import { ArrowRight, Close } from '@shared/icons';
import ShareButton from '@shared/components/share-buttons';
import usePrevious from '@shared/hooks/use-previous';

const HexagramTextWrapper = ({ animated, children, visible }) => {
    if (animated) {
        return (
            <CSSTransition timeout={500} classNames="reading" in={visible}>
                { children }
            </CSSTransition>
        );
    }

    return (
        <>{children}</>
    );
};

const HexagramText = ({
    animated,
    hexagram,
    reading,
    onCloseClick,
    canSave,
    saveReading,
    showShare
}) => {
    const [persistedHexagram, setPersistedHexagram] = useState({});
    const [persistedReading, setPersistedReading] = useState('');

    const prevHexagram = usePrevious(hexagram);
    const prevReading = usePrevious(reading);

    const readingContinerRef = useRef(null);

    useEffect(() => {
        const hexNum = hexagram && hexagram.hexNum;
        const prevHexNum = prevHexagram && prevHexagram.hexNum;

        // Saving data to state to persist data for animation duration
        if (hexNum && prevHexNum !== hexNum) {
            setPersistedHexagram(hexagram);
        }

        if (reading && prevReading !== reading) {
            setPersistedReading(reading);

            // Reset scrolling only when receiving new data
            if (reading !== persistedReading) {
                readingContinerRef.current.scrollTop = 0;
            }
        }
    }, [hexagram, reading, prevHexagram, prevReading, persistedReading]);

    const visible = !!hexagram.hexNum;
    const readingClasses = ['reading', { 'reading-open': visible }];
    const changingHexData = persistedHexagram.changingHexData;

    const navigate = useNavigate();

    return (
        <HexagramTextWrapper animated={animated} visible={visible}>
            <article id="reading" className={classNames(readingClasses)}
                ref={readingContinerRef}>
                <div className="reading-innerwrap">
                    <button className="close-btn" onClick={onCloseClick}>
                        <Close size={'17px'} />
                    </button>
                    <div id="readingContent" className="reading-content">
                        <div className="intro">
                            <h2 className="h1">{persistedHexagram.hexNum}</h2>
                            <h3 className="h1">{persistedHexagram.hexTitle}</h3>
                            <p className="h2">
                                { `${persistedHexagram.upper} over ${persistedHexagram.lower}` }
                            </p>
                            <div className="hexagram-row">
                                {
                                    visible ?
                                        <StaticHex
                                            hexKey={persistedHexagram.hexKey}
                                            hexNum={persistedHexagram.hexNum}
                                            displayTitles={false}
                                        /> : ''
                                }
                                <div className="trigram-translation">
                                    <div>
                                        <i>above</i>
                                        <span>{persistedHexagram.upperName}</span>
                                        <span>
                                            { `${persistedHexagram.upper} (${persistedHexagram.upperAspect})` }
                                        </span>
                                    </div>
                                    <div>
                                        <i>below</i>
                                        <span>{persistedHexagram.lowerName}</span>
                                        <span>
                                            { `${persistedHexagram.lower} (${persistedHexagram.lowerAspect})` }
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="readingText" className="reading-text">
                            <div dangerouslySetInnerHTML={{ __html: persistedReading.reading }} ></div>
                            {
                                persistedReading && persistedReading.lines ?
                                    <div>
                                        <h2>THE LINES</h2>
                                        <div dangerouslySetInnerHTML={{ __html: persistedReading.lines }}></div>
                                    </div>
                                    : ''
                            }
                        </div>
                        {
                            (persistedHexagram.changingLines && !!persistedHexagram.changingLines.length) ?
                                <div className="changing-hex">
                                    <StaticHex
                                        hexKey={persistedHexagram.hexKey}
                                        hexNum={persistedHexagram.hexNum}
                                        displayTitles={true}
                                        changingLines={persistedHexagram.changingLines}
                                    />
                                    <div className="changes">
                                        <div className="arrow-right">
                                            <ArrowRight />
                                        </div>
                                        changes into
                                    </div>
                                    <StaticHex
                                        hexKey={changingHexData.hexKey}
                                        hexNum={changingHexData.hexNum}
                                        displayTitles={true}
                                        onHexClick={navigate.bind(null, `/hexagrams/${changingHexData.hexNum}`)}
                                    />
                                </div>
                                : ''
                        }
                        {
                            canSave ?
                                <button className="save-btn default" onClick={saveReading}>
                                    Save
                                </button>
                                : ''
                        }
                        {
                            showShare ?
                                <ShareButton/>
                                : ''
                        }
                        <div className="reading-footer">
                            <small>Wilhelm, Richard, 1924. <i>The I Ching</i>. Translated by Cary F. Baynes, 1950. New York: Bollingen Foundation, Inc.</small>
                        </div>
                    </div>
                </div>
            </article>
        </HexagramTextWrapper>
    );
};

HexagramText.propTypes = {
    onCloseClick: PropTypes.func,
    hexagram: PropTypes.object,
    reading: PropTypes.object,
    animated: PropTypes.bool,
    saveReading: PropTypes.func,
    canSave: PropTypes.bool,
    showShare: PropTypes.bool
};

HexagramText.defaultProps = {
    onCloseClick: () => {},
    hexagram: {},
    reading: {},
    animated: true,
    saveReading: () => {},
    showShare: true
};

export default HexagramText;
