const APP = {
    BURGER_ROUTE_WHITELIST: [
        /\/$/,
        /\/oracle\/?$/,
        /\/hexagrams\/?$/,
        /\/saved-readings\/?$/,
        /\/about\/?$/,
        /\/privacy-policy\/?$/
    ],
    STORAGE_KEY: 'ask_iching',
    BANNER_TIMEOUT: 3500,
    ORACLE_STAGES: {
        START_READING: 'START_READING',
        TOSSING_COINS: 'TOSSING_COINS',
        HEXAGRAM_COMPLETED: 'HEXAGRAM_COMPLETED',
        RESET_ORACLE: 'RESET_ORACLE'
    },
    ORIGIN: {
        development: 'http://localhost:3000',
        production: 'https://www.ask-iching.com'
    },
    TITLES: {
        PREFIX: 'Ask I Ching | ',
        HOME: 'Free Online I Ching App',
        ORACLE: 'Oracle',
        HEXAGRAMS: 'Hexagrams',
        HEXAGRAM: 'Hexagram',
        SAVED_READINGS: 'Saved Readings',
        SAVED_READING: 'Saved Reading',
        SHARED_READING: 'Reading',
        ABOUT: 'About',
        PRIVACY_POLICY: 'Privacy Policy',
        MISSING: '404'
    }
};

export default APP;
