import React from 'react';
import { Link } from 'react-router-dom';
import { getPageTitle } from '@shared/helpers/global-helper';

import tails from '@shared/images/coin-tails.svg';

const MissingRoute = () => (
    <div className="wrapper missing-route-wrapper">
        {getPageTitle('MISSING')}
        <div className="missing-route">
            <h1>
                <span>4</span>
                <span className="coin">
                    <div className="flipper tails">
                        <div className="back">
                            <img src={tails} alt="0" />
                        </div>
                    </div>
                </span>
                <span>4</span>
            </h1>
            <h2 className="h1">Uh-oh, looks like we couldn't find that page.</h2>
            <p className="h2"><Link to="/oracle">Ask the oracle</Link> to find your way.</p>
        </div>
    </div>
);

export default MissingRoute;
