export const types = {
    RESET_TOSS: 'RESET_TOSS',
    TOSS_COMPLETE: 'TOSS_COMPLETE',
    TOSS_COINS: 'TOSS_COINS',
    HEXAGRAM_COMPLETE: 'HEXAGRAM_COMPLETE',
    ASK_QUESTION: 'ASK_QUESTION',
    RESET_ORACLE: 'RESET_ORACLE',
    RESET_ORACLE_COMPLETE: 'RESET_ORACLE_COMPLETE'
};

// Coins
export const tossCoins = () => ({
    type: types.TOSS_COINS,
});

export const onTossComplete = (currentToss, currentHand) => ({
    type: types.TOSS_COMPLETE,
    currentToss,
    currentHand
});

export const resetToss = () => ({
    type: types.RESET_TOSS
});

// Hexagram
export const onHexagramComplete = (hexKey, hexLines) => ({
    type: types.HEXAGRAM_COMPLETE,
    hexKey,
    hexLines
});

// Question
export const askQuestion = (question) => ({
    type: types.ASK_QUESTION,
    question
});

export const resetOracle = () => ({
    type: types.RESET_ORACLE
});

export const onResetOracleComplete = () => ({
    type: types.RESET_ORACLE_COMPLETE
});

