import React from 'react';
import { connect } from 'react-redux';

import MenuContainer from './shared/components/menu';
import Banner from './shared/components/banner';
import SocialBlockedModal from './shared/components/social-blocked-modal';

import actions from './actions';
import { isNode } from './shared/helpers/global-helper';

// Import css only if on the client
if (!isNode()) {
    require('./css/app.scss');
    require('react-responsive-modal/styles.css');
}

const App = (props) => {
    const {
        isBannerShown,
        toggleBanner,
        bannerMsg
    } = props;

    return (
        <div className="App" id="App">
            <header>
                <h1 className="main-title">I Ching</h1>
                <MenuContainer />
            </header>
            <Banner
                message={bannerMsg}
                isShown={isBannerShown}
                closeBanner={toggleBanner.bind(null, false)}
            />
            <SocialBlockedModal />
            <div id="app-content" className="app-content">
                {props.children}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    isBannerShown: state.isBannerShown,
    bannerMsg: state.bannerMsg,
});

const AppContainer = connect(
    mapStateToProps,
    { ...actions }
)(App);

export default AppContainer;
