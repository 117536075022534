/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import PropTypes from 'prop-types';

const Edit = ({ size, fill }) => (
    <svg id="Ebene_1" version="1.1" viewBox="0 0 1280 1024" x="0px" y="0px" width={size} height={size} xmlSpace="preserve">
        <path fill={fill} d="M1033.6,249.6L902.4,118.4C873.6,89.6,816,92.8,784,128L323.2,585.6L204.8,947.2L566.4,832l460.8-460.8&#xD;&#xA;&#x9;c19.2-19.2,28.8-41.6,28.8-67.2C1056,281.6,1049.6,262.4,1033.6,249.6z M982.4,323.2l-448,448l-227.2,73.6l73.6-227.2l448-448&#xD;&#xA;&#x9;c6.4-6.4,17.7-14.8,28.8-6.4l131.2,131.2c3.2,3.2,3.2,6.4,3.2,6.4C992,310.4,988.8,316.8,982.4,323.2z"/>
    </svg>
);

Edit.propTypes = {
    size: PropTypes.string,
    fill: PropTypes.string
};

Edit.defaultProps = {
    size: '32px',
    fill: '#FDF1F1'
};

export default Edit;
