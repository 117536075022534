const HEX_MAP = {
    '111111': {
        'number': 1,
        'title': 'The Creative'
    },
    '000000': {
        'number': 2,
        'title': 'The Receptive'
    },
    '100010': {
        'number': 3,
        'title': 'Difficulty at the Beginning'
    },
    '010001': {
        'number': 4,
        'title': 'Youthful Folly'
    },
    '111010': {
        'number': 5,
        'title': 'Waiting (Nourishment)'
    },
    '010111': {
        'number': 6,
        'title': 'Conflict'
    },
    '010000': {
        'number': 7,
        'title': 'The Army'
    },
    '000010': {
        'number': 8,
        'title': 'Holding Together (Union)'
    },
    '111011': {
        'number': 9,
        'title': 'The Taming Power of the Small'
    },
    '110111': {
        'number': 10,
        'title': 'Treading (Conduct)'
    },
    '111000': {
        'number': 11,
        'title': 'Peace'
    },
    '000111': {
        'number': 12,
        'title': 'Standstill (Stagnation)'
    },
    '101111': {
        'number': 13,
        'title': 'Fellowship with Men'
    },
    '111101': {
        'number': 14,
        'title': 'Possession in Great Measure'
    },
    '001000': {
        'number': 15,
        'title': 'Modesty'
    },
    '000100': {
        'number': 16,
        'title': 'Enthusiasm'
    },
    '100110': {
        'number': 17,
        'title': 'Following'
    },
    '011001': {
        'number': 18,
        'title': 'Work on What Has Been Spoiled (Decay)'
    },
    '110000': {
        'number': 19,
        'title': 'Approach'
    },
    '000011': {
        'number': 20,
        'title': 'Contemplation (View)'
    },
    '100101': {
        'number': 21,
        'title': 'Biting Through'
    },
    '101001': {
        'number': 22,
        'title': 'Grace'
    },
    '000001': {
        'number': 23,
        'title': 'Splitting Apart'
    },
    '100000': {
        'number': 24,
        'title': 'Return (The Turning Point)'
    },
    '100111': {
        'number': 25,
        'title': 'Innocence (The Unexpected)'
    },
    '111001': {
        'number': 26,
        'title': 'The Taming Power of the Great'
    },
    '100001': {
        'number': 27,
        'title': 'The Corners of the Mouth (Providing Nourishment)'
    },
    '011110': {
        'number': 28,
        'title': 'Preponderence of the Great'
    },
    '010010': {
        'number': 29,
        'title': 'The Abysmal (Water)'
    },
    '101101': {
        'number': 30,
        'title': 'The Clinging (Fire)'
    },
    '001110': {
        'number': 31,
        'title': 'Influence (Wooing)'
    },
    '011100': {
        'number': 32,
        'title': 'Duration'
    },
    '001111': {
        'number': 33,
        'title': 'Retreat'
    },
    '111100': {
        'number': 34,
        'title': 'The Power of the Great'
    },
    '000101': {
        'number': 35,
        'title': 'Progress'
    },
    '101000': {
        'number': 36,
        'title': 'Darkening of the Light'
    },
    '101011': {
        'number': 37,
        'title': 'The Family (The Clan)'
    },
    '110101': {
        'number': 38,
        'title': 'Opposition'
    },
    '001010': {
        'number': 39,
        'title': 'Obstruction'
    },
    '010100': {
        'number': 40,
        'title': 'Deliverance'
    },
    '110001': {
        'number': 41,
        'title': 'Decrease'
    },
    '100011': {
        'number': 42,
        'title': 'Increase'
    },
    '111110': {
        'number': 43,
        'title': 'Break-through (Resoluteness)'
    },
    '011111': {
        'number': 44,
        'title': 'Coming to Meet'
    },
    '000110': {
        'number': 45,
        'title': 'Gathering Together (Massing)'
    },
    '011000': {
        'number': 46,
        'title': 'Pushing Upward'
    },
    '010110': {
        'number': 47,
        'title': 'Oppresssion (Exhaustion)'
    },
    '011010': {
        'number': 48,
        'title': 'The Well'
    },
    '101110': {
        'number': 49,
        'title': 'Revolution (Molting)'
    },
    '011101': {
        'number': 50,
        'title': 'The Caldron'
    },
    '100100': {
        'number': 51,
        'title': 'The Arousing (Shock, Thunder)'
    },
    '001001': {
        'number': 52,
        'title': 'Keeping Still (Mountain)'
    },
    '001011': {
        'number': 53,
        'title': 'Development (Gradual Progress)'
    },
    '110100': {
        'number': 54,
        'title': 'The Marrying Maiden'
    },
    '101100': {
        'number': 55,
        'title': 'Abundance (Fullness)'
    },
    '001101': {
        'number': 56,
        'title': 'The Wanderer'
    },
    '011011': {
        'number': 57,
        'title': 'The Gentle (The Penetrating, Wind)'
    },
    '110110': {
        'number': 58,
        'title': 'The Joyous (Lake)'
    },
    '010011': {
        'number': 59,
        'title': 'Dispersion (Dissolution)'
    },
    '110010': {
        'number': 60,
        'title': 'Limitation'
    },
    '110011': {
        'number': 61,
        'title': 'Inner Truth'
    },
    '001100': {
        'number': 62,
        'title': 'Preponderence of the Small'
    },
    '101010': {
        'number': 63,
        'title': 'After Completion'
    },
    '010101': {
        'number': 64,
        'title': 'Before Completion'
    }
};

export default HEX_MAP;
