/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import PropTypes from 'prop-types';

const Refresh = ({ size, fill }) => (
    <svg id="Ebene_1" version="1.1" viewBox="0 0 32 32" x="0px" y="0px" width={size} height={size} xmlSpace="preserve">
        <g>
            <path fill={fill} d="M10.2,10.4l-3.6,1c1-2.3,2.8-4.1,5.1-5.2C14.2,5.1,17,5,19.6,6c2.8,1,5.1,3.3,6.1,6.1l1.9-0.7c-1.3-3.4-3.9-6-7.3-7.2&#xD;&#xA;&#x9;&#x9;c-3.1-1.1-6.5-1-9.5,0.4c-2.6,1.2-4.7,3.3-6,5.8L4,6.9L2,7.4l1.9,6.8l6.8-1.9L10.2,10.4z M5.3,11.8L5.3,11.8L5.3,11.8L5.3,11.8z"/>
            <path fill={fill} d="M30,24.6l-1.9-6.8l-6.8,1.9l0.5,1.9l3.6-1c-1,2.3-2.8,4.1-5.1,5.2c-2.5,1.2-5.4,1.3-8,0.3c-2.8-1-5.1-3.3-6.1-6.1l-1.9,0.7&#xD;&#xA;&#x9;&#x9;c1.3,3.4,3.9,6,7.3,7.2c1.4,0.5,2.8,0.8,4.3,0.8c1.8,0,3.5-0.4,5.2-1.1c2.6-1.2,4.7-3.3,6-5.8l0.9,3.4L30,24.6z M26.7,20.2&#xD;&#xA;&#x9;&#x9;L26.7,20.2L26.7,20.2L26.7,20.2z"/>
        </g>
    </svg>
);

Refresh.propTypes = {
    size: PropTypes.string,
    fill: PropTypes.string
};

Refresh.defaultProps = {
    size: '32px',
    fill: '#FDF1F1'
};

export default Refresh;
