import { combineReducers } from 'redux';

import * as shared from './shared/reducers';
import * as oracle from './oracle/reducers';
import * as hexagrams from './hexagrams/reducers';
import * as reading from './reading/reducers';
import * as savedReadings from './saved-readings/reducers';

const createRootReducer = () => combineReducers({
    ...shared,
    ...oracle,
    ...hexagrams,
    ...reading,
    ...savedReadings
});

export default createRootReducer;
