/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import PropTypes from 'prop-types';

const Trash = ({ size, fill }) => (
    <svg id="Ebene_1" version="1.1" viewBox="0 0 32 32" x="0px" y="0px" width={size} height={size} xmlSpace="preserve">
        <path fill={fill} d="M25.1,6H22V4.3C22,3,21.2,2,19.9,2h-7.8C10.8,2,10,3,10,4.3V6H6.9C5.6,6,5,6.9,5,8.2V13h1v14.7C6,29,6.9,30,8.2,30h15.6&#xD;&#xA;&#x9;c1.3,0,2.2-1,2.2-2.3V13h1V8.2C27,6.9,26.4,6,25.1,6z M12,4.3C12,4.1,11.9,4,12.1,4h7.8C20.1,4,20,4.1,20,4.3V6h-8V4.3z M23.8,28&#xD;&#xA;&#x9;H8.2C8,28,8,27.9,8,27.7V13h16v14.7C24,27.9,24,28,23.8,28z M25,11h-0.9H7.9H7V8h18V11z"/>
    </svg>
);

Trash.propTypes = {
    size: PropTypes.string,
    fill: PropTypes.string
};

Trash.defaultProps = {
    size: '32px',
    fill: '#FDF1F1'
};

export default Trash;
