export const types = {
    SAVE_READING: 'SAVE_READING',
    DELETE_READING: 'DELETE_READING',
    LOAD_SAVED_READING: 'LOAD_SAVED_READING'
};

export const saveReading = (hexagram, question, hexagramText) => ({
    type: types.SAVE_READING,
    hexagram,
    question,
    hexagramText
});

export const deleteReading = (readings) => ({
    type: types.DELETE_READING,
    readings
});

export const loadSavedReading = (savedReading) => ({
    type: types.LOAD_SAVED_READING,
    savedReading
});
