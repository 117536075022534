import React from 'react';
import { hydrateRoot } from 'react-dom/client';

import { configureStore } from '@/configure-store';
import { ClientRoot } from '@/root';

const preloadedState = window.__PRELOADED_STATE__;

delete window.__PRELOADED_STATE__;

document.addEventListener('DOMContentLoaded', () => {
    const { store } = configureStore(preloadedState);

    hydrateRoot(
        document.getElementById('root'),
        <ClientRoot store={store} />,
    );
});
