import { useEffect, useState } from "react";
import { debounce } from "lodash";

const hasWindow = typeof window !== 'undefined';

const getWindowDimensions = () => {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
        width,
        height,
    };
};

export function useWindowSize(delay = 250) {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        if (hasWindow) {
            function handleResize() {
                setWindowDimensions(getWindowDimensions());
            }
            const debouncedHandleResize = debounce(handleResize, delay);
            window.addEventListener("resize", debouncedHandleResize);
            return () => window.removeEventListener("resize", debouncedHandleResize);
        }
    }, [delay]);

    return windowDimensions;
}
