import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import Close from '@shared/icons/close';
import APP from '@constants/APP';

const Banner = ({ message, isShown, closeBanner }) => {
    let bannerTimer;

    const onCloseClick = () => {
        closeBanner();
        window.clearTimeout(bannerTimer);
    };

    const autoClose = () => (
        bannerTimer = window.setTimeout(closeBanner, APP.BANNER_TIMEOUT)
    );

    return <CSSTransition
        timeout={300}
        classNames="banner"
        in={isShown}
        onEntered={autoClose}
    >
        <div className={classNames('banner', { 'show': isShown })}>
            <div className="banner-msg">
                {message}
            </div>
            <button className="close-btn" onClick={onCloseClick}>
                <Close size={'17px'} />
            </button>
        </div>
    </CSSTransition>;
};

Banner.propTypes = {
    message: PropTypes.string,
    isShown: PropTypes.bool,
    closeBanner: PropTypes.func
};

export default Banner;
