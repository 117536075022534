import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import qs from 'query-string';

import StaticHex from '@shared/components/static-hex';
import HexagramText from '@shared/components/hexagram-text';
import Loader from '@shared/components/loader';
import ShareButton from '@shared/components/share-buttons';
import { Heart, Refresh } from '@shared/icons';
import actions from '@actions';
import { getSharedHexagram } from './selectors';
import { getUnescapedQuestion } from './selectors';
import { getPageTitle } from '@shared/helpers/global-helper';
import { canSaveReading } from '@shared/helpers/hexagram-helper';
import { useWindowSize } from '@shared/hooks/use-window-size';
import HEX_MAP from '@constants/HEX_MAP';

const Reading = (props) => {
    const {
        hexagram,
        reading,
        question,
        setSharedReadingData,
        saveReading,
        savedReadings,
    } = props;

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const query = location.search.slice(1);
        const search = qs.parse(query);
        const { question, lines } = search;
        let { hex } = search;

        hex = parseInt(hex, 10);

        if (hex > 64 || hex < 1 ||
            (!question || !hex)) {
            navigate('/');
        } else {
            const hexKey = Object.keys(HEX_MAP).find(key => HEX_MAP[key].number === hex);
            let changingLines = lines || [];

            if (typeof lines === 'string') {
                changingLines = [parseInt(lines, 10)];
            } else {
                changingLines = changingLines.map((line) => parseInt(line, 10));
            }

            setSharedReadingData(hex, hexKey, question, changingLines);
        }
    }, []); // eslint-disable-line

    const { height } = useWindowSize();
    const [windowHeight, setWindowHeight] = useState(null);
    useEffect(() => {
        setWindowHeight(height);
    }, [height]);

    if (isEmpty(hexagram)) {
        return (
            <div className="wrapper" style={{ height: windowHeight }}>
                <Loader />
            </div>
        );
    }

    const handleCloseClick = () => {
        navigate('/oracle');
    };

    const canSave = canSaveReading(reading, savedReadings, question, hexagram);

    return <div className="wrapper" style={{ height: windowHeight, overflowY: `hidden` }}>
        {getPageTitle('SHARED_READING', question)}
        <article className="oracle reading-open">
            <h2 className="question-title show-question">{question}</h2>
            <div className="hexagram-wrapper show">
                <StaticHex
                    hexKey={hexagram.hexKey}
                    title={hexagram.hexTitle}
                    hexNum={hexagram.hexNum}
                    changingLines={hexagram.changingLines}
                    displayTitles={false}
                />
                <div className="hex-description">
                    <h2 id="hexNum" className="hex-num h1">{hexagram.hexNum}</h2>
                    <p className="hex-name h1">{hexagram.hexTitle}</p>
                    <div className="buttons">
                        <button className="reset-btn icon-btn default"
                            onClick={handleCloseClick}
                        >
                            <Refresh/>
                        </button>
                        {
                            canSave ?
                                <button className="save-btn icon-btn default"
                                    onClick={saveReading.bind(null, hexagram, question, reading)}>
                                    <Heart height="32px" width="40px"/>
                                </button>
                                : ''
                        }
                        <ShareButton isIcon/>
                    </div>
                </div>
            </div>
        </article>
        <HexagramText
            onCloseClick={handleCloseClick}
            hexagram={hexagram}
            reading={reading}
            animated={false}
            saveReading={saveReading.bind(null, hexagram, question, reading)}
            canSave={canSave}/>
    </div>;
};

const mapStateToProps = (state) => ({
    question: getUnescapedQuestion(state),
    hexagram: getSharedHexagram(state),
    reading: state.sharedReadingData.reading,
    savedReadings: state.savedReadings
});

const mapDispatchToProps = {
    setSharedReadingData: actions.setSharedReadingData,
    saveReading: actions.saveReading
};

export default connect(mapStateToProps, mapDispatchToProps)(Reading);
