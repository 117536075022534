import { takeLatest, put, call } from 'redux-saga/effects';
import actions, { types } from '@actions';
import { getHexagramText } from '@api';

export function* fetchHexagramTextSaga({ hex, readingType, changingLines }) {
    try {
        const response = yield call(getHexagramText, hex, changingLines);

        if (readingType === 'static') {
            yield put(actions.fetchStaticHexagramTextSuccess(response));
        } else if (readingType === 'shared') {
            yield put(actions.fetchSharedHexagramTextSuccess(response));
        } else {
            yield put(actions.fetchHexagramTextSuccess(response));
        }
    } catch (error) {
        yield put(actions.toggleBanner(true, 'I\'m sorry but our server seems to be down. Please check back again later.'));

        if (readingType === 'static') {
            yield put(actions.fetchStaticHexagramTextFailure(error));
        } else if (readingType === 'shared') {
            yield put(actions.fetchSharedHexagramTextFailure(error));
        } else {
            yield put(actions.fetchHexagramTextFailure(error));
        }
    }
}

export function* watchFetchHexagramText() {
    yield takeLatest(types.FETCH_HEXAGRAM_TEXT, fetchHexagramTextSaga);
}
