import React from 'react';
import PropTypes from 'prop-types';
import Line from './line';

const getLines = (hexKey) => {
    const arr = [];

    for (let i=0; i < 6; i+=1) {
        const val = parseInt(hexKey[i], 10);
        arr.push({ val });
    }

    return arr;
};

const isChangingLine = (changingLines, lineNum) => (
    !!changingLines && !!changingLines.length && changingLines.indexOf(lineNum) >= 0
);

const StaticHex = (props) => {
    const {
        hexKey,
        changingLines,
        title,
        hexNum,
        onHexClick,
        displayTitles,
        caption,
    } = props;

    const lines = hexKey ? getLines(hexKey) : [];

    return <div className="static-hexagram" onClick={onHexClick}>
        <ul className="hexagram">
            { lines.map((line, i) => (
                <Line
                    key={`static-hex-line-${hexNum}-${i}`}
                    isSolid={line.val}
                    isChanging={isChangingLine(changingLines, i + 1)}
                />
            )
            ).reverse()}
        </ul>
        {
            displayTitles ? <div>
                <div>{hexNum}</div>
                <div>{title || ''}</div>
                <div className="hex-caption">{caption || ''}</div>
            </div> : ''
        }
    </div>;
};

StaticHex.propTypes = {
    hexKey: PropTypes.string,
    title: PropTypes.string,
    hexNum: PropTypes.number,
    onHexClick: PropTypes.func,
    changingLines: PropTypes.array,
    displayTitles: PropTypes.bool,
    caption: PropTypes.string
};

StaticHex.defaultTypes = {
    onHexClick: () => {}
};

export default StaticHex;
