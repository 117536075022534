import { createSelector } from 'reselect';
import { getReading } from '@shared/helpers/hexagram-helper';

export const getOracleHexKey = state => state.oracleHexKey;
export const getOracleChangingLines = state => state.oracleChangingLines;
export const getOracleHexLines = state => state.oracleHexLines;
export const getOracleQuestion = state => state.question;
export const getOracleHexNum = state => state.oracleHexNum;

export const getHexagram = createSelector(
    [getOracleHexKey, getOracleChangingLines, getOracleHexLines],
    (hexKey, changingLines, hexLines) => (
        hexKey ? { ...getReading(hexKey, changingLines), hexLines } : { hexLines }
    )
);
