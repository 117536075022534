import { createSelector } from 'reselect';
import { getReading } from '@shared/helpers/hexagram-helper';

const getStaticHexKey = state => state.staticHexKey;

export const getStaticHexagram = createSelector(
    [getStaticHexKey],
    (staticHexKey) => (
        staticHexKey ? getReading(staticHexKey) : {}
    )
);
