import React from 'react';
import { Helmet } from 'react-helmet';
import APP from '@constants/APP';

export const isNode = () => {
    try {
        return typeof "process" !== "undefined" && process && process.versions && process.versions.node;
    } catch (err) {
        if (err.message === "process is not defined") {
            return false;
        }
    }
};

export const getPageTitle = (title, extendedTitle = '') => {
    const pageTitle =
        `${APP.TITLES[title] || title} ${extendedTitle ? `- ` + extendedTitle : ''}`;
    return (
        <>
            <Helmet>
                <title>{APP.TITLES.PREFIX + pageTitle}</title>
            </Helmet>
        </>
    );
};
