/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import PropTypes from 'prop-types';

const CircleCheck = ({ size, fill }) => (
    <svg id="Ebene_1" version="1.1" viewBox="0 0 32 32" x="0px" y="0px" width={size} height={size} xmlSpace="preserve">
        <g>
            <path fill={fill} d="M16,2C8.3,2,2,8.3,2,16s6.3,14,14,14s14-6.3,14-14S23.7,2,16,2z M16,28C9.4,28,4,22.6,4,16S9.4,4,16,4s12,5.4,12,12&#xD;&#xA;&#x9;&#x9;S22.6,28,16,28z"/>
            <polygon fill={fill} points="14.4,18.7 10.1,15.6 8.9,17.2 14.7,21.4 23.3,11.3 21.7,10 &#x9;"/>
        </g>
    </svg>
);

CircleCheck.propTypes = {
    size: PropTypes.string,
    fill: PropTypes.string
};

CircleCheck.defaultProps = {
    size: '32px',
    fill: '#FDF1F1'
};

export default CircleCheck;
