/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import PropTypes from 'prop-types';

const Share = ({ size, fill }) => (
    <svg id="Ebene_1" version="1.1" viewBox="0 0 32 32" x="0px" y="0px" width={size} height={size} xmlSpace="preserve">
        <path fill={fill} d="M24,19c-1.6,0-3,0.8-3.9,1.9l-7.3-3.6c0.1-0.4,0.2-0.8,0.2-1.3c0-0.4-0.1-0.9-0.2-1.3l7.3-3.6C21,12.2,22.4,13,24,13&#xD;&#xA;&#x9;c2.8,0,5-2.2,5-5c0-2.8-2.2-5-5-5s-5,2.2-5,5c0,0.4,0.1,0.9,0.2,1.3l-7.3,3.6C11,11.8,9.6,11,8,11c-2.8,0-5,2.2-5,5s2.2,5,5,5&#xD;&#xA;&#x9;c1.6,0,3-0.8,3.9-1.9l7.3,3.6C19.1,23.1,19,23.6,19,24c0,2.8,2.2,5,5,5s5-2.2,5-5C29,21.2,26.8,19,24,19z M24,5c1.7,0,3,1.3,3,3&#xD;&#xA;&#x9;c0,1.7-1.3,3-3,3s-3-1.3-3-3C21,6.3,22.3,5,24,5z M8,19c-1.7,0-3-1.3-3-3s1.3-3,3-3s3,1.3,3,3S9.7,19,8,19z M24,27c-1.7,0-3-1.3-3-3&#xD;&#xA;&#x9;c0-1.7,1.3-3,3-3s3,1.3,3,3C27,25.7,25.7,27,24,27z"/>
    </svg>
);

Share.propTypes = {
    size: PropTypes.string,
    fill: PropTypes.string
};

Share.defaultProps = {
    size: '32px',
    fill: '#FDF1F1'
};

export default Share;
