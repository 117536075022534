import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import actions from '@actions';
import QUESTION from '@constants/QUESTION';
import usePrevious from '@shared/hooks/use-previous';

const {
    PLACEHOLDER_TEXT,
    PLACEHOLDER_INTERVAL,
    ERROR_TEXT,
    QUESTION_MAX_LENGTH
} = QUESTION;

const Question = ({
    question,
    isFormVisible,
    askQuestion
}) => {
    const [placeholder, setPlaceholder] = useState({
        text: PLACEHOLDER_TEXT[0],
        index: 0
    });
    const [questionInput, setQuestionInput] = useState(question);
    const [errorMessage, setErrorMessage] = useState('');
    const prevIsFormVisible = usePrevious(isFormVisible);

    const cyclePlaceholderText = useCallback(() => {
        const newIndex = placeholder.index === PLACEHOLDER_TEXT.length - 1 ?
            0 : placeholder.index + 1;

        setPlaceholder({
            text: PLACEHOLDER_TEXT[newIndex],
            index: newIndex
        });
    }, [placeholder, setPlaceholder]);

    useEffect(() => {
        const placeHolderInterval =
            window.setInterval(cyclePlaceholderText, PLACEHOLDER_INTERVAL);

        return () => {
            window.clearInterval(placeHolderInterval);
        };
    }, [cyclePlaceholderText]);

    useEffect(() => {
        if (isFormVisible && !prevIsFormVisible) {
            setQuestionInput(question);
        }
    }, [question, isFormVisible, prevIsFormVisible]);

    const handleFormSubmit = (e) => {
        e.preventDefault();

        if (!questionInput) {
            setErrorMessage(ERROR_TEXT[Math.floor(Math.random() * 4)]);
        } else {
            askQuestion(questionInput);
        }
    };

    const handleFocus = () => {
        setErrorMessage('');
    };

    const handleInputChange = (e) => {
        setQuestionInput(e.target.value);
        setErrorMessage('');
    };

    return (
        <div className="question">
            <div className={classNames('question-container', { 'hide-question-input': !isFormVisible })}>
                <h2 className="h1">Ask the oracle</h2>
                <form onSubmit={handleFormSubmit}>
                    <input type="text" className="question"
                        value={questionInput}
                        placeholder={placeholder.text}
                        onFocus={handleFocus}
                        onChange={handleInputChange}
                        maxLength={QUESTION_MAX_LENGTH} />
                    <span className={classNames('error-message',
                        { 'hidden': !errorMessage })}>
                        {errorMessage}
                    </span>
                    <button type="submit" className="ask-btn">Ask</button>
                </form>
            </div>

            <h2 className={classNames('question-title', { 'show-question': !isFormVisible })}>{question}</h2>
        </div>
    );
};

const mapStateToProps = (state) => ({
    question: state.question,
    isFormVisible: !state.oracleStage
});

const mapDispatchToProps = {
    askQuestion: actions.askQuestion
};

export default connect(mapStateToProps, mapDispatchToProps)(Question);
