/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import PropTypes from 'prop-types';

const Burger = ({ fill, size }) => (
    <svg id="Ebene_1" version="1.1" width={size} height={size} viewBox="0 0 32 32" x="0px" y="0px" xmlSpace="preserve">
        <g>
            <rect fill={fill} height="2" width="26" x="3" y="15"/>
            <rect fill={fill} height="2" width="26" x="3" y="9"/>
            <rect fill={fill} height="2" width="26" x="3" y="21"/>
        </g>
    </svg>
);

Burger.propTypes = {
    fill: PropTypes.string,
    size: PropTypes.string
};

Burger.defaultProps = {
    fill: '#FDF1F1',
    size: '32px'
};

export default Burger;
