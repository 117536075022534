import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';
import COINS from '@constants/COINS';

import heads from '@shared/images/coin-heads.svg';
import tails from '@shared/images/coin-tails.svg';

const Coin = (props) => {
    const {
        idx,
        isTails,
        className,
        isFlipping,
        timeout,
        visible,
        onAnimationComplete
    } = props;

    const onAnimationEnd = () => {
        if (idx === 2) {
            onAnimationComplete();
        }
    };

    const animateClasses = {
        'flipping': isFlipping
    };

    return (
        <CSSTransition timeout={timeout} classNames="coin" in={visible}>
            <li className={classNames(className, 'coin', animateClasses)}
                onAnimationEnd={onAnimationEnd}>
                <div className={classNames('flipper', { 'tails': isTails })}>
                    <div className="front">
                        <img src={heads} alt="heads" />
                    </div>
                    <div className="back">
                        <img src={tails} alt="tails" />
                    </div>
                </div>
            </li>
        </CSSTransition>
    );
};

Coin.propTypes = {
    isTails: PropTypes.bool,
    isFlipping: PropTypes.bool,
    onAnimationComplete: PropTypes.func,
    timeout: PropTypes.number,
    visible: PropTypes.bool
};

Coin.defaultTypes = {
    isTails: false,
    isFlipping: false,
    onAnimationComplete: () => {},
    timeout: COINS.COIN_BOUNCE_ANIMATION_DURATION,
    visible: false
};

export default Coin;
