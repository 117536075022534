export const types = {
    FETCH_HEXAGRAM_TEXT: 'FETCH_HEXAGRAM_TEXT',
    FETCH_HEXAGRAM_TEXT_SUCCESS: 'FETCH_HEXAGRAM_TEXT_SUCCESS',
    FETCH_HEXAGRAM_TEXT_FAILURE: 'FETCH_HEXAGRAM_TEXT_FAILURE',
    TOGGLE_BANNER: 'TOGGLE_BANNER',
    TOGGLE_MENU: 'TOGGLE_MENU',
    TOGGLE_SOCIAL_BLOCKED_MODAL: 'TOGGLE_SOCIAL_BLOCKED_MODAL'
};

export const fetchHexagramText = (hex, readingType, changingLines) => ({
    type: types.FETCH_HEXAGRAM_TEXT,
    hex,
    readingType,
    changingLines
});

export const fetchHexagramTextSuccess = (response) => ({
    type: types.FETCH_HEXAGRAM_TEXT_SUCCESS,
    response
});

export const fetchHexagramTextFailure = (error) => ({
    type: types.FETCH_HEXAGRAM_TEXT_FAILURE,
    error
});

export const toggleBanner = (shouldShow, bannerMsg) => ({
    type: types.TOGGLE_BANNER,
    shouldShow,
    bannerMsg
});

export const toggleMenu = (isOpen = false) => {
    return {
        type: types.TOGGLE_MENU,
        payload: { isOpen }
    };
};

export const toggleSocialBlockedModal = (shouldShow) => ({
    type: types.TOGGLE_SOCIAL_BLOCKED_MODAL,
    shouldShow
});
