import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import Question from './components/question';
import Hexagram from './components/hexagram';
import Coins from './components/coins';
import HexagramText from '@shared/components/hexagram-text';
import actions from '@actions';
import { getHexagram } from './selectors';
import APP from '@constants/APP';
import { getPageTitle } from '@shared/helpers/global-helper';
import { canSaveReading } from '@shared/helpers/hexagram-helper';
import { useWindowSize } from '@shared/hooks/use-window-size';

const { ORACLE_STAGES } = APP;

const Oracle = ({
    oracleStage,
    question,
    hexagram,
    resetOracle,
    oracleHexagramText,
    saveReading,
    savedReadings
}) => {
    const { height } = useWindowSize();
    const [windowHeight, setWindowHeight] = useState(null);
    useEffect(() => {
        resetOracle();
    }, []); // eslint-disable-line

    useEffect(() => {
        setWindowHeight(height);
    }, [height]);

    const coinsVisible = oracleStage === ORACLE_STAGES.START_READING ||
        oracleStage === ORACLE_STAGES.TOSSING_COINS;

    const oracleClasses = ['oracle',
        { 'reading-open': !!hexagram.hexNum }];

    const canSave =
        canSaveReading(oracleHexagramText, savedReadings, question, hexagram);

    const handleCloseClick = () => {
        resetOracle();
        // Restore url to /oracle since it was updated to the reading url
        // using window.history in the saga to show the reading result
        window.history.pushState(null, '', '/oracle');
    };

    return <div className="wrapper" style={{ height: windowHeight }}>
        {getPageTitle('ORACLE', question)}
        <article className={classNames(oracleClasses)}>
            <Question />
            <Hexagram
                showDescription={oracleStage === ORACLE_STAGES.HEXAGRAM_COMPLETED}
                saveReading={saveReading.bind(null, hexagram, question, oracleHexagramText)}
                canSave={canSave}/>
            <Coins visible={coinsVisible} />
        </article>
        <HexagramText
            onCloseClick={handleCloseClick}
            hexagram={hexagram}
            reading={oracleHexagramText}
            animated={true}
            saveReading={saveReading.bind(null, hexagram, question, oracleHexagramText)}
            canSave={canSave}/>
    </div>;
};

const mapStateToProps = (state) => ({
    question: state.question,
    oracleStage: state.oracleStage,
    hexagram: getHexagram(state),
    oracleHexagramText: state.oracleHexagramText,
    savedReadings: state.savedReadings,
    hexLines: state.hexLines
});

const OracleContainer = connect(
    mapStateToProps,
    { ...actions }
)(Oracle);

export default OracleContainer;
