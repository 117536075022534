import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import COINS from '@constants/COINS';
import Coin from './coin';
import actions from '@actions';

const { MAX_TURNS, NUM_COINS, COIN_BOUNCE_ANIMATION_DURATION } = COINS;

const toss = () => {
    const num = Math.round(Math.random());

    return {
        isTails: num === 0,
        num
    };
};

const Coins = ({
    visible,
    tossDisabled,
    tossCoins,
    onTossComplete,
    currentHand,
    currentToss
}) => {
    const [flipping, setFlipping] = useState(false);

    const handleCoinAnimationEnd = () => {
        setFlipping(false);
    };

    const handleClick = () => {
        tossCoins();

        if (currentToss < MAX_TURNS) {
            const newToss = currentToss + 1;

            const newHand = [];

            // Toss three coins
            for (let i=0; i < NUM_COINS; i+=1) {
                const data = toss();
                newHand.push(data);
            }

            setFlipping(true);

            onTossComplete(newToss, newHand);
        }
    };

    return (
        <div className={classNames('coin-area',
            { 'hide-coins': !visible })}>
            <ul className="coins">
                { currentHand.map((coin, i) => <Coin key={`coin-${i + 1}`}
                    className={`coin${i + 1}`}
                    idx={i}
                    isTails={coin.isTails}
                    isFlipping={flipping}
                    visible={visible}
                    timeout={COIN_BOUNCE_ANIMATION_DURATION}
                    onAnimationComplete={handleCoinAnimationEnd}
                />
                )}
            </ul>
            {
                visible && <button className="toss-btn"
                    disabled={tossDisabled}
                    onClick={handleClick}>
                    Toss
                </button>
            }
        </div>
    );
};

Coins.propTypes = {
    visible: PropTypes.bool,
};

Coins.defaultTypes = {
    visible: false,
};

const mapStateToProps = (state) => ({
    currentHand: state.currentHand,
    currentToss: state.currentToss,
    tossDisabled: state.tossDisabled,
});

const mapDispatchToProps = {
    tossCoins: actions.tossCoins,
    onTossComplete: actions.onTossComplete
};

export default connect(mapStateToProps, mapDispatchToProps)(Coins);
