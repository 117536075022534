const TRIGRAM_MAP = {
    '111': {
        'element': 'Heaven',
        'aspect': 'The Creative',
        'name': 'Ch\'ien'
    },
    '100': {
        'element': 'Thunder',
        'aspect': 'The Arousing',
        'name': 'Chên'
    },
    '010': {
        'element': 'Water',
        'aspect': 'The Abysmal',
        'name': 'K\'an'
    },
    '001': {
        'element': 'Mountain',
        'aspect': 'Keeping Still',
        'name': 'Kên'
    },
    '000': {
        'element': 'Earth',
        'aspect': 'The Receptive',
        'name': 'K\'un'
    },
    '011': {
        'element': 'Wind',
        'aspect': 'The Gentle',
        'name': 'Sun'
    },
    '101': {
        'element': 'Fire',
        'aspect': 'The Clinging',
        'name': 'Li'
    },
    '110': {
        'element': 'Lake',
        'aspect': 'The Joyous',
        'name': 'Tui'
    }
};

export default TRIGRAM_MAP;
