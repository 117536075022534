import { types } from '@actions';
import assignIn from 'lodash/assignIn';

export const sharedReadingData = (state = {}, action) => {
    const newState = assignIn({}, state);

    switch (action.type) {
    case types.SET_SHARED_READING_DATA:
        newState.question = action.question;
        newState.hexKey = action.hexKey;
        newState.changingLines = action.changingLines;
        return newState;
    case types.FETCH_SHARED_HEXAGRAM_TEXT_SUCCESS:
        newState.reading = action.response.body;
        return newState;
    default:
        return state;
    }
};
