import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import StaticHex from '@shared/components/static-hex';
import { Circle, CircleCheck } from '@shared/icons';

const ListItem = (props) => {
    const {
        reading,
        shouldRemove,
        onClick,
        onCheckClick,
        onTransitionEnd,
        isSelected
    } = props;

    return (
        <li
            key={`saved-reading-${reading.id}`}
            className={classNames({ 'remove': shouldRemove })}
            onTransitionEnd={onTransitionEnd}
            onClick={onClick.bind(null, reading.id)}
        >
            <button className="check-btn" onClick={onCheckClick.bind(null, reading.id)}>
                {
                    isSelected ?
                        <CircleCheck /> : <Circle />
                }
            </button>
            <div className="saved-question-wrapper">
                <StaticHex
                    hexKey={reading.hexKey}
                    hexNum={reading.hexNum}
                    displayTitles={false}
                />
                <div className="saved-reading-data">
                    <div className="question"><h2>{reading.question}</h2></div>
                    <div className="date">
                        <span className="full-date">{moment(reading.time).format('lll')}</span>
                        <span className="short-date">{moment(reading.time).format('L')}</span>
                    </div>
                    <div className="num">{reading.hexData.number}&nbsp;&nbsp;</div>
                    <div className="title">{reading.hexData.title}</div>
                </div>
            </div>
        </li>
    );
};

export default ListItem;
