export const types = {
    SET_SHARED_READING_DATA: 'SET_SHARED_READING_DATA',
    FETCH_SHARED_HEXAGRAM_TEXT_SUCCESS: 'FETCH_SHARED_HEXAGRAM_TEXT_SUCCESS',
    FETCH_SHARED_HEXAGRAM_TEXT_FAILURE: 'FETCH_SHARED_HEXAGRAM_TEXT_FAILURE'
};

export const setSharedReadingData = (hexNum, hexKey, question, changingLines) => ({
    type: types.SET_SHARED_READING_DATA,
    hexNum,
    hexKey,
    question,
    changingLines
});

export const fetchSharedHexagramTextSuccess = (response) => ({
    type: types.FETCH_SHARED_HEXAGRAM_TEXT_SUCCESS,
    response
});

export const fetchSharedHexagramTextFailure = (error) => ({
    type: types.FETCH_SHARED_HEXAGRAM_TEXT_FAILURE,
    error
});
