import { types } from '@actions';
import { APP, HEX_MAP } from '@constants';

export const DEFAULT_HAND = [
    { isTails: false },
    { isTails: false },
    { isTails: false }
];

export const DEFAULT_TOSS = 0;

export const DEFAULT_HEX_LINES = [{}, {}, {}, {}, {}, {}];

const { ORACLE_STAGES } = APP;

// Coins
export const currentHand = (state = DEFAULT_HAND, action) => {
    switch (action.type) {
    case types.TOSS_COMPLETE:
        return action.currentHand;
    case types.RESET_ORACLE:
        return DEFAULT_HAND;
    default:
        return state;
    }
};

export const currentToss = (state = DEFAULT_TOSS, action) => {
    switch (action.type) {
    case types.TOSS_COMPLETE:
        return action.currentToss;
    case types.RESET_ORACLE:
        return DEFAULT_TOSS;
    default:
        return state;
    }
};

export const tossDisabled = (state = false, action) => {
    switch (action.type) {
    case types.TOSS_COINS:
        return true;
    case types.RESET_TOSS:
        return false;
    case types.RESET_ORACLE:
        return false;
    default:
        return state;
    }
};

// Hexagram
export const oracleHexKey = (state = null, action) => {
    switch (action.type) {
    case types.HEXAGRAM_COMPLETE:
        return action.hexKey;
    case types.RESET_ORACLE:
        return null;
    default:
        return state;
    }
};

export const oracleHexNum = (state = null, action) => {
    switch (action.type) {
    case types.HEXAGRAM_COMPLETE:
        return HEX_MAP[action.hexKey].number;
    case types.RESET_ORACLE:
        return null;
    default:
        return state;
    }
};

export const oracleHexLines = (state = DEFAULT_HEX_LINES, action) => {
    switch (action.type) {
    case types.HEXAGRAM_COMPLETE:
        return action.hexLines;
    case types.RESET_ORACLE:
        return DEFAULT_HEX_LINES;
    default:
        return state;
    }
};

export const oracleChangingLines = (state = [], action) => {
    switch (action.type) {
    case types.HEXAGRAM_COMPLETE:
        const newState = [];
        action.hexLines.forEach((line, i) => {
            if (line.changing) {
                newState.push(i + 1);
            }
        });
        return newState;
    case types.RESET_ORACLE:
        return [];
    default:
        return state;
    }
};

// Question
export const question = (state = '', action) => {
    switch (action.type) {
    case types.ASK_QUESTION:
        return action.question;
    case types.RESET_ORACLE:
        return '';
    default:
        return state;
    }
};

export const oracleStage = (state = null, action) => {
    switch (action.type) {
    case types.ASK_QUESTION:
        return ORACLE_STAGES.START_READING;
    case types.TOSS_COINS:
        return ORACLE_STAGES.TOSSING_COINS;
    case types.HEXAGRAM_COMPLETE:
        return ORACLE_STAGES.HEXAGRAM_COMPLETED;
    case types.RESET_ORACLE:
    case types.RESET_ORACLE_COMPLETE:
        return null;
    default:
        return state;
    }
};

export const oracleHexagramText = (state = null, action) => {
    switch (action.type) {
    case types.FETCH_HEXAGRAM_TEXT_SUCCESS:
        return action.response.body;
    case types.RESET_ORACLE:
        return null;
    case types.FETCH_HEXAGRAM_TEXT_FAILURE:
    default:
        return state;
    }
};
