import React from 'react';
import { useNavigate } from 'react-router-dom';
import HEX_MAP from '@constants/HEX_MAP';
import StaticHex from '@shared/components/static-hex';
import { getPageTitle } from '@shared/helpers/global-helper';

const Hexagrams = () => {
    const navigate = useNavigate();

    return <div className="wrapper all-hexagrams">
        {getPageTitle('HEXAGRAMS')}
        <ul className="hexagrams-list">
            {
                Object.keys(HEX_MAP)
                    .sort((a, b)=> {
                        const obj1 = HEX_MAP[a];
                        const obj2 = HEX_MAP[b];
                        return obj1.number - obj2.number;
                    })
                    .map((key, i)=> {
                        const obj = HEX_MAP[key];
                        return <li key={`hex-list-${key}`}>
                            <StaticHex
                                hexKey={key}
                                title={obj.title}
                                hexNum={obj.number}
                                onHexClick={navigate.bind(null, `/hexagrams/${obj.number}`)}
                                displayTitles={true}
                            />
                        </li>;
                    })
            }
        </ul>
    </div>;
};

export default Hexagrams;
