import React from 'react';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import APP from '@constants/APP';
import { Close, Burger } from '../icons';
import { toggleMenu } from '../actions';

const isBurgerRoute = (route) => (
    !!APP.BURGER_ROUTE_WHITELIST.find((regex) => regex.test(route))
);


const Menu = ({ toggleMenu, isOpen, oracleHexNum }) => {
    const onToggleBtnClick = () => toggleMenu(!isOpen);
    const onItemClick = () => toggleMenu(false);

    const location = useLocation();
    const route = location.pathname;

    const isBurgerHidden =
        (!!oracleHexNum && route === '/oracle') || !isBurgerRoute(route);

    return (
        <div className="menu-wrapper">
            {
                isBurgerHidden ?
                    '' :
                    (<button className="menu-toggle-btn"
                        onClick={onToggleBtnClick}>
                        <Burger />
                        <span>Toggle Nav Menu</span>
                    </button>)
            }
            <div className={`menu${isOpen ? ' menu-open' : ''}`}>
                <button className="menu-close-btn" onClick={onItemClick}>
                    <Close size={'17px'} />
                    <span>Close Menu</span>
                </button>
                <nav>
                    <Link to="/"
                        onClick={onItemClick}>
                        Home
                    </Link>
                    <Link to="/oracle"
                        onClick={onItemClick}>
                        Oracle
                    </Link>
                    <Link to="/hexagrams"
                        onClick={onItemClick}>
                        All Hexagrams
                    </Link>
                    <Link to="/saved-readings"
                        onClick={onItemClick}>
                        Saved Readings
                    </Link>
                    <Link to="/about"
                        onClick={onItemClick}>
                        About
                    </Link>
                    <Link to="/privacy-policy"
                        onClick={onItemClick}>
                        Privacy Policy
                    </Link>
                </nav>
            </div>
        </div>
    );
};

const mapStateToProps = ({ menu, oracleHexNum }) => {
    return {
        isOpen: menu.isOpen,
        oracleHexNum,
    };
};

const MenuContainer = connect(
    mapStateToProps,
    { toggleMenu }
)(Menu);

export default MenuContainer;
