import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Line = ({ isSolid, isChanging, onLineComplete }) => (
    <li className={classNames({ 'broken': isSolid !== undefined && !isSolid,
        'solid': isSolid, 'changing': isChanging })}
    onTransitionEnd={onLineComplete}>
        <span></span>
    </li>
);

Line.propTypes = {
    isSolid: PropTypes.number,
    isChanging: PropTypes.bool,
    onLineComplete: PropTypes.func
};

Line.defaultProps = {
    isSolid: undefined,
    isChanging: undefined,
    onLineComplete: () => {}
};

export default Line;
