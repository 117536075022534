import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Line from '@shared/components/line';
import { Heart, Refresh } from '@shared/icons';
import ShareButton from '@shared/components/share-buttons';
import actions from '@actions';
import { getHexagram } from './../selectors';

const DEFAULT_STATE = {
    hexKey: null,
    hexagramComplete: false,
    showDescription: false
};

class Hexagram extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...DEFAULT_STATE,
            lines: [...props.hexagram.hexLines]
        };
    }

    componentDidUpdate(prevProps) {
        const { currentHand, currentToss, hexagram } = this.props;

        if (currentToss === prevProps.currentToss
                || currentHand === prevProps.currentHand) {
            return;
        }

        if (currentToss && currentHand.length) {
            this.calculateLine(currentHand, currentToss);
        } else {
            // Reset
            this.setState({
                ...DEFAULT_STATE,
                lines: [...hexagram.hexLines]
            });
        }
    }

    calculateLine(currentHand, currentToss) {
        let val = 0;
        const { lines } = this.state;

        /*
         * Add values of all three coins
         * to get calculate the line
         * 0 = 3 tails / Yang Changing
         * 1 = 1 heads, 2 tails / Yin
         * 2 = 2 heads, 1 tails / Yang
         * 3 = 3 heads / Yin Changing
         */
        const MAP = {
            0: { val: 1, changing: true },
            1: { val: 0, changing: false },
            2: { val: 1, changing: false },
            3: { val: 0, changing: true }
        };

        currentHand.forEach((item) => {
            val += item.num;
        });


        lines[currentToss - 1] = MAP[val.toString()];

        this.setState({
            lines
        });

        if (currentToss === 6) {
            this.calculateHexagram();
        }
    }

    calculateHexagram() {
        const key = this.state.lines.map(line => line.val.toString()).join('');

        this.setState({
            hexKey: key,
            hexagramComplete: true
        });
    }

    onLineComplete(lineNum, e) {
        const {
            currentToss,
            onResetOracleComplete,
            resetToss,
            onHexagramComplete
        } = this.props;

        const { hexagramComplete, hexKey, lines } = this.state;

        if (lineNum === 6 && !currentToss) {
            onResetOracleComplete();
        } else if (e.propertyName === 'transform') {
            // Don't fire for every property transition event
            resetToss();

            if (hexagramComplete) {
                onHexagramComplete(hexKey, lines);
            }
        }
    }

    render() {
        const {
            visible,
            resetOracle,
            showDescription,
            hexagram,
            saveReading,
            canSave
        } = this.props;

        const { lines } = this.state;

        return <div
            className={classNames('hexagram-wrapper', { 'show': visible })}>
            <ul className="hexagram">
                { lines.map((line, i) => (
                    <Line
                        key={`line-${i + 1}`}
                        isSolid={line.val}
                        isChanging={line.changing}
                        onLineComplete={this.onLineComplete.bind(this, i + 1)} />
                )
                ).reverse()}
            </ul>
            { showDescription && <div className="hex-description">
                <h2 id="hexNum" className="hex-num h1">{hexagram.hexNum}</h2>
                <p className="hex-name h1">{hexagram.hexTitle}</p>
                <div className="buttons">
                    <button className="reset-btn icon-btn default" onClick={resetOracle}>
                        <Refresh/>
                    </button>
                    {
                        canSave ?
                            <button className="save-btn icon-btn default"
                                onClick={saveReading}>
                                <Heart height="32px" width="40px"/>
                            </button>
                            : ''
                    }
                    <ShareButton isIcon/>

                </div>
            </div>}
        </div>;
    }
}

Hexagram.propTypes = {
    visible: PropTypes.bool,
    showDescription: PropTypes.bool,
    saveReading: PropTypes.func,
    canSave: PropTypes.bool
};

Hexagram.defaultProps = {
    visible: true,
    showDescription: false,
};

const mapStateToProps = (state) => ({
    currentHand: state.currentHand,
    currentToss: state.currentToss,
    tossDisabled: state.tossDisabled,
    hexagram: getHexagram(state)
});

const mapDispatchToProps = {
    resetToss: actions.resetToss,
    onHexagramComplete: actions.onHexagramComplete,
    resetOracle: actions.resetOracle,
    onResetOracleComplete: actions.onResetOracleComplete
};

export default connect(mapStateToProps, mapDispatchToProps)(Hexagram);
