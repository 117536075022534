/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import PropTypes from 'prop-types';

const Circle = ({ size, fill }) => (
    <svg id="Ebene_1" version="1.1" viewBox="0 0 32 32" x="0px" y="0px" width={size} height={size} xmlSpace="preserve">
        <g>
            <path fill={fill} d="M16,30C8.3,30,2,23.7,2,16S8.3,2,16,2s14,6.3,14,14S23.7,30,16,30z M16,4C9.4,4,4,9.4,4,16s5.4,12,12,12s12-5.4,12-12&#xD;&#xA;&#x9;&#x9;S22.6,4,16,4z"/>
        </g>
    </svg>
);

Circle.propTypes = {
    size: PropTypes.string,
    fill: PropTypes.string
};

Circle.defaultProps = {
    size: '32px',
    fill: '#FDF1F1'
};

export default Circle;
