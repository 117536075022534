import request from 'superagent';
import { isNode } from '@shared/helpers/global-helper';

const prefix = isNode() ? 'http://localhost:8000/' : '/';

export const getHexagramText = (hex, changingLines) => {
    return request
        .get(prefix + 'api')
        .query({
            hex,
            lines: changingLines
        });
};
