import { createSelector } from 'reselect';
import { getReading } from '@shared/helpers/hexagram-helper';
import QUESTION from '@constants/QUESTION';

const getSharedHexKey = state => state.sharedReadingData.hexKey;
const getSharedChangingLines = state => state.sharedReadingData.changingLines;
const getSharedQuestion = state => state.sharedReadingData.question;

export const getSharedHexagram = createSelector(
    [getSharedHexKey, getSharedChangingLines],
    (hexKey, changingLines) => (
        hexKey ? getReading(hexKey, changingLines) : {}
    )
);

export const getUnescapedQuestion = createSelector(
    [getSharedQuestion],
    (question) => {
        let formatted = unescape(question);
        const { QUESTION_MAX_LENGTH } = QUESTION;
        if (formatted.length > QUESTION_MAX_LENGTH) {
            formatted = formatted.slice(0, QUESTION_MAX_LENGTH);
        }
        return formatted;
    }
);

