import { types } from '@actions';
import { v4 as uuid } from "uuid";
import moment from 'moment';
import HEX_MAP from '@constants/HEX_MAP';

export const savedReadings = (state = [], action) => {
    const newState = [...state];
    switch (action.type) {
    case types.SAVE_READING:
        const { question, hexagramText } = action;
        const { hexKey, changingLines } = action.hexagram;
        const reading = {
            id: uuid(),
            time: moment(new Date()).toJSON(),
            hexData: HEX_MAP[hexKey],
            hexKey,
            changingLines,
            question,
            hexagramText
        };
        newState.push(reading);
        return newState;
    case types.DELETE_READING:
        const { readings } = action;
        return newState.filter(o => !readings[o.id]
        );
    default:
        return state;
    }
};

export const savedReading = (state = {}, action) => {
    switch (action.type) {
    case types.LOAD_SAVED_READING:
        return action.savedReading;
    default:
        return state;
    }
};
