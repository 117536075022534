/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import PropTypes from 'prop-types';

const Heart = ({ width, height, fill }) => (
    <svg id="Ebene_1" version="1.1" viewBox="0 0 1280 1024" x="0px" y="0px" width={width} height={height} xmlSpace="preserve">
        <g>
            <path fill={fill} d="M640,931.2L617.6,912C499.2,803.2,428.8,732.8,364.8,659.2c-32-35.2-67.2-70.4-105.6-112c-57.6-67.2-80-150.4-64-233.6&#xD;&#xA;&#x9;&#x9;c16-76.8,64-144,131.2-179.2c41.6-19.2,76.8-28.8,108.8-28.8c70.4,0,134.4,38.4,201.6,128C739.2,112,835.2,80,947.2,137.6&#xD;&#xA;&#x9;&#x9;c67.2,32,115.2,99.2,131.2,179.2c16,83.2-6.4,166.4-60.8,233.6c-70.4,70.4-112,115.2-147.2,150.4&#xD;&#xA;&#x9;&#x9;c-57.6,60.8-99.2,102.4-211.2,211.2L640,931.2z M438.4,169.6c-25.6,0-51.2,6.4-80,22.4c-48,25.6-86.4,73.6-96,134.4&#xD;&#xA;&#x9;&#x9;c-12.8,64,3.2,128,48,179.2c38.4,38.4,73.6,76.8,105.6,112c60.8,64,121.6,131.2,227.2,227.2C736,752,777.6,710.4,832,656&#xD;&#xA;&#x9;&#x9;c35.2-38.4,76.8-80,147.2-150.4c41.6-48,57.6-112,44.8-176c-12.8-57.6-48-108.8-96-134.4c-25.6-12.8-51.2-19.2-73.6-19.2&#xD;&#xA;&#x9;&#x9;C754.7,176,640,339.2,640,339.2L614.4,304C528,182.4,473.6,169.6,438.4,169.6z"/>
        </g>
    </svg>
);

Heart.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    fill: PropTypes.string
};

Heart.defaultProps = {
    width: '20px',
    height: '16px',
    fill: '#FDF1F1'
};

export default Heart;
