import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import tails from '@shared/images/coin-tails.svg';
import heads from '@shared/images/coin-heads.svg';

const StaticCoin = ({ coinType }) => {
    const coin = coinType === 'heads' ? heads : tails;
    return <span className="coin">
        <div className={classNames('flipper', coinType)}>
            <div className={classNames({ 'back': coinType === 'tails', 'front': coinType === 'heads' })}>
                <img src={coin} alt="0" />
            </div>
        </div>
    </span>;
};

StaticCoin.propTypes = {
    coinType: PropTypes.string
};

export default StaticCoin;
