import { takeLatest, put, select } from 'redux-saga/effects';
import actions, { types } from '@actions';
import HEX_MAP from '@constants/HEX_MAP';
import { getReadingUrl } from '@shared/helpers/hexagram-helper';
import {
    getOracleChangingLines,
    getOracleHexNum,
    getOracleQuestion
} from './selectors';

export function* onHexagramCompleteSaga({ hexKey }) {
    const changingLines = yield select(getOracleChangingLines);
    const hexNum = yield select(getOracleHexNum);
    const question = yield select(getOracleQuestion);

    try {
        yield put(actions.fetchHexagramText(HEX_MAP[hexKey].number, 'normal', changingLines));

        // Update url without causing page change via router
        const readingUrl = getReadingUrl(question, hexNum, changingLines);
        window.history.pushState(null, '', readingUrl);
    } catch (error) {
        // TODO: Handle API error/add logging
        console.error(error); // eslint-disable-line no-console
    }
}

export function* watchOnHexagramComplete() {
    yield takeLatest(types.HEXAGRAM_COMPLETE, onHexagramCompleteSaga);
}
