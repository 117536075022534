import { all, fork } from 'redux-saga/effects';
import { watchOnStaticHexagramComplete } from './hexagrams/sagas';
import { watchSharedReading } from './reading/sagas';
import { watchSaveReading } from './saved-readings/sagas';
import { watchOnHexagramComplete } from './oracle/sagas';
import * as shared from './shared/sagas';

export default function* rootSaga() {
    yield all([
        fork(watchOnStaticHexagramComplete),
        fork(watchSharedReading),
        fork(watchSaveReading),
        fork(watchOnHexagramComplete),
        fork(shared.watchFetchHexagramText)
    ]);
}
