import { createSelector } from 'reselect';
import { getReading } from '@shared/helpers/hexagram-helper';

const getSavedReadingHexKey = state => state.savedReading.hexKey;
const getSavedReadingChangingLines = state => state.savedReading.changingLines;

export const getSavedReadingHexagram = createSelector(
    [getSavedReadingHexKey, getSavedReadingChangingLines],
    (hexKey, changingLines) => (
        hexKey ? getReading(hexKey, changingLines) : {}
    )
);
