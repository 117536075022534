import qs from 'query-string';
import { isEmpty } from 'lodash';
import { HEX_MAP, TRIGRAM_MAP } from '@constants';

function calculateTrigrams(hexKey) {
    const lower = hexKey.substring(0, 3),
        upper = hexKey.substring(3, 6);

    return {
        upper: TRIGRAM_MAP[upper].element,
        lower: TRIGRAM_MAP[lower].element,
        upperAspect: TRIGRAM_MAP[upper].aspect,
        lowerAspect: TRIGRAM_MAP[lower].aspect,
        upperName: TRIGRAM_MAP[upper].name,
        lowerName: TRIGRAM_MAP[lower].name
    };
}

function calculateChangingData(hexKey, changingLines) {
    const split = hexKey.split('');

    changingLines.forEach((lineNum) => {
        const index = lineNum - 1;
        let val = parseInt(split[index], 10);

        val = val === 0 ? 1 : 0;

        val = val.toString();

        split.splice(index, 1, val);
    });

    const changingHexKey = split.join('');

    const changingHexData = {
        hexNum: HEX_MAP[changingHexKey].number,
        hexKey: changingHexKey,
        hexTitle: HEX_MAP[changingHexKey].title
    };

    return {
        changingHexData,
        changingLines
    };
}

export function getReading(hexKey, changingLines) {
    const trigrams = calculateTrigrams(hexKey);
    const changingData = changingLines ? calculateChangingData(...arguments) : null;

    return {
        hexKey,
        hexNum: HEX_MAP[hexKey].number,
        hexTitle: HEX_MAP[hexKey].title,
        ...changingData,
        ...trigrams
    };
}

export function getReadingUrl(question, hex, lines) {
    const query = qs.stringify({
        question,
        hex,
        lines
    });

    return `/reading?${query}`;
}

// Checks if the user has already saved a reading
// or if reading data available (no backend error)
export function canSaveReading(
    oracleHexagramText,
    savedReadings,
    question,
    hexagram
) {
    return (
        !isEmpty(oracleHexagramText) && (savedReadings.filter(o => (
            o.question === question && o.hexKey === hexagram.hexKey
        )).length === 0)
    );
}
