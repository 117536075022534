import * as hexagrams from './hexagrams/actions';
import * as reading from './reading/actions';
import * as oracle from './oracle/actions';
import * as savedReadings from './saved-readings/actions';
import * as shared from './shared/actions';

export const types = {
    ...hexagrams.types,
    ...reading.types,
    ...oracle.types,
    ...savedReadings.types,
    ...shared.types
};

const tempActions = {
    ...hexagrams,
    ...reading,
    ...oracle,
    ...savedReadings,
    ...shared
};

delete tempActions.types;

const actions = {
    ...tempActions
};

export default actions;
