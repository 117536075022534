import { put, take, takeLatest } from 'redux-saga/effects';
import actions, { types } from '@actions';
import HEX_MAP from '@constants/HEX_MAP';

export function* onStaticHexagramCompleteSaga({ hexKey }) {
    try {
        const hexNum = HEX_MAP[hexKey].number;
        yield put(actions.fetchHexagramText(hexNum, 'static', 'all'));
        yield take(types.FETCH_STATIC_HEXAGRAM_TEXT_SUCCESS);
    } catch (error) {
        // TODO: Handle API error/add logging
        console.error(error); // eslint-disable-line no-console
    }
}

export function* watchOnStaticHexagramComplete() {
    yield takeLatest(types.STATIC_HEXAGRAM_COMPLETE, onStaticHexagramCompleteSaga);
}
